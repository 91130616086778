import React, { useEffect, useRef } from 'react';
import { OTPInput, REGEXP_ONLY_DIGITS, SlotProps } from 'input-otp';

interface OtpInputProps {
  name: string;
  defaultValue?: string;
  onPasteComplete: (code: string) => void;
  errorMessage?: string;
}
const Slot: React.FC<SlotProps> = (props) => {
  return (
    <div className="cell shrink">
      <div
        className={`flex-container align-center align-middle bordered radius bg-white
          slot ${props.isActive ? 'active' : ''}`}
      >
        <div>{props.char ?? props.placeholderChar}</div>
        {props.hasFakeCaret && <div className="fake-caret bg-black " />}
      </div>
    </div>
  );
};

const OtpInputField: React.FC<OtpInputProps> = ({
  onPasteComplete,
  errorMessage,
  ...field
}) => {
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
  const pastedRef = useRef(false);

  useEffect(() => {
    if (inputRefs.current[0]) {
      inputRefs.current[0].focus();
    }
  }, []);

  return (
    <div className="grid-x grid-padding-x grid-padding-y-small ">
      <div className="cell">
        <OTPInput
          autoFocus
          containerClassName="flex-container align-center"
          maxLength={6}
          onComplete={(code: string) => {
            if (pastedRef.current) {
              onPasteComplete(code);
            }
            pastedRef.current = false;
          }}
          onPaste={() => {
            pastedRef.current = true;
          }}
          pattern={REGEXP_ONLY_DIGITS}
          render={({ slots }) => (
            <div className="grid-x grid-padding-x-small grid-padding-y-small">
              {slots.map((slot, idx) => (
                <Slot key={idx} {...slot} />
              ))}
            </div>
          )}
          {...field}
        />
        {errorMessage && (
          <p className="cell small-8 form-error is-visible">{errorMessage}</p>
        )}
      </div>
    </div>
  );
};

export default OtpInputField;
