import { addSuccessMessage, addInfoMessage, clearMessages } from '../common/messages';
import { getJobResult, fetchJobStatus } from '../helpers';
import getMessages from '../i18n';
import type Jed from 'jed';
import {
  getLocalStorageItem,
  removeLocalStorageItem,
  setLocalStorageItem,
} from '../utils';

const IMPORT_JOB_QUERY_PARAMETER = 'import_job_id';

interface ImportJobResult {
  new: number;
  updated: number;
  failed: number;
}

/**
 * Return the import job ID from URL or cache.
 */
const getImportJobId = (): string | null => {
  const urlParams = new URLSearchParams(window.location.search);
  const jobId = urlParams.get(IMPORT_JOB_QUERY_PARAMETER);
  if (jobId) {
    setLocalStorageItem('importJobId', jobId);
  }

  return getLocalStorageItem('importJobId');
};

/**
 * Clear the import job ID from cache and URL.
 */
const clearImportJobId = (): void => {
  const url = new URL(window.location.href);
  url.searchParams.delete(IMPORT_JOB_QUERY_PARAMETER);
  removeLocalStorageItem('importJobId');
  window.history.pushState(null, '', url.href);
};

/**
 * Setup feedback for an import job.
 */
const setupImportJobFeedback = async (): Promise<void> => {
  const jobId = getImportJobId();
  if (!jobId) {
    return;
  }

  const importStarted = (i18n: Jed): void => {
    addInfoMessage(i18n.gettext('An import of contacts has been started'), {
      description: i18n.gettext('You will be notified when the import is complete.'),
    });
  };

  const importFinished = (i18n: Jed, result: ImportJobResult): void => {
    clearMessages();
    addSuccessMessage(
      i18n.sprintf(
        i18n.ngettext(
          'Finished importing %(count)s contact',
          'Finished importing %(count)s contacts',
          result.new
        ),
        { count: result.new }
      ),
      {
        description: [
          result.updated > 0
            ? i18n.sprintf(
                i18n.ngettext(
                  '%(count)s contact already existed and was updated.',
                  '%(count)s contacts already existed and were updated.',
                  result.updated
                ),
                { count: result.updated }
              )
            : '',
          result.failed > 0
            ? i18n.sprintf(
                i18n.ngettext(
                  '%(count)s contact failed to import due to invalid email.',
                  '%(count)s contacts failed to import due to invalid emails.',
                  result.failed
                ),
                { count: result.failed }
              )
            : '',
        ]
          .filter(Boolean)
          .join(' '),
      }
    );

    clearImportJobId();
  };

  const i18n = await getMessages();
  const data = await fetchJobStatus<ImportJobResult>(jobId);

  if (data?.status === 'started' || data?.status === 'queued') {
    importStarted(i18n);
    const data = await getJobResult<ImportJobResult>(jobId, 2);
    if (data?.status === 'finished' && data?.result) {
      importFinished(i18n, data.result);
    }
  } else if (data?.status === 'finished' && data?.result) {
    importFinished(i18n, data.result);
  }
};

export { setupImportJobFeedback };
