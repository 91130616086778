import PropTypes from 'prop-types';

import '../../scss/components/gauge.scss';

/**
 * <Gauge /> component for rendering an SVG percent based circle.
 *
 * Inspired by a medium.com article by @pppped:
 */
// eslint-disable-next-line max-len
// https://medium.com/@pppped/how-to-code-a-responsive-circular-percentage-chart-with-svg-and-css-3632f8cd7705
const Gauge = ({ percent, label = '', description = '', inverse = false }) => {
  const gaugeCircleClass = `gauge-circle gauge-percent${inverse ? '-inverse' : ''}-${
    Math.floor(percent / 10) * 10
  }`;

  return (
    <svg viewBox="0 0 36 36" className="gauge">
      <path
        d="M18 2.0845
            a 15.9155 15.9155 0 0 1 0 31.831
            a 15.9155 15.9155 0 0 1 0 -31.831"
        className="gauge-bg"
      />
      <path
        d="M18 2.0845
            a 15.9155 15.9155 0 0 1 0 31.831
            a 15.9155 15.9155 0 0 1 0 -31.831"
        className={gaugeCircleClass}
        strokeDasharray={`${percent}, 100`}
      />
      {label && (
        <text x="18" y="19.2" className="gauge-label">
          {label}
        </text>
      )}
      {description && (
        <text x="18" y="22.2" className="gauge-description">
          {description}
        </text>
      )}
    </svg>
  );
};

Gauge.propTypes = {
  percent: PropTypes.number.isRequired,
  label: PropTypes.string,
  description: PropTypes.string,
  inverse: PropTypes.bool,
};

export default Gauge;
