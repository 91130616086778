import React, { useContext } from 'react';

import ChecklistItem from '../../components/checklist-item';
import CopyToClipboard from '../../components/copy-to-clipboard';
import { Status } from '../../common/constants';
import { TranslationsContext } from '../../components/translate';

interface UnsubscribeChecklistItemProps {
  hasUnsubscribeLink: boolean;
  unsubscribeLink: string;
  checklistItemProps: object;
}

const UnsubscribeChecklistItem: React.FC<UnsubscribeChecklistItemProps> = ({
  hasUnsubscribeLink,
  unsubscribeLink,
  ...checklistItemProps
}) => {
  const i18n = useContext(TranslationsContext);
  const descriptionText = hasUnsubscribeLink
    ? i18n.gettext('The SMS contains a link to unsubscribe from future SMS campaigns.')
    : i18n.gettext('The SMS must include the following URL for unsubscribing:');

  return (
    <ChecklistItem
      title={i18n.gettext('Unsubscribing')}
      status={hasUnsubscribeLink ? Status.VALID : Status.INVALID}
      description={
        <div>
          <p>{descriptionText}</p>
          {!hasUnsubscribeLink && <CopyToClipboard text={unsubscribeLink} />}
        </div>
      }
      {...checklistItemProps}
    />
  );
};

export default UnsubscribeChecklistItem;
