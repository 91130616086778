/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import { useCallback, useContext, useReducer } from 'react';
import ReactDOM from 'react-dom';

import Icon from 'common/lib/components/icon';
import ContentEditor from './content-editor';
import Tab from './tabs';
import { get as getRequest, patch } from '../utils/api';
import updateState, { get as getState } from '../state';
import { TranslationsContext } from './translate';
import SavedStatus from './saved-status';
import undoable from '../utils/undoable';

const IN_PROD = process.env.NODE_ENV === 'production';

/**
 * Return editor config for a specific resource.
 *
 * @param {String} resource
 * @param {Number} id
 */
const getConfig = (resource, id, resource_url, { ckeditor = {}, ...defaults } = {}) => {
  const user = global.MailMojo?.User;
  const uploadUrl = `/resources/${resource}/${id}/images/upload/`;
  const cropUrl = `/resources/${resource}/${id}/images/crop/`;
  // The embed provider URL for CKEditor, utilizing self-hosted Iframely service.
  const embedProviderUrl = '/resources/oembed?url={url}&callback={callback}';

  return {
    ...defaults,
    cropUrl,
    resourcePublicUrl: resource_url,
    ckeditor: {
      ...ckeditor,
      imageUploadUrl: uploadUrl,
      filebrowserImageUploadUrl: uploadUrl,
      embed_provider: embedProviderUrl,
    },
    enableExperimentalFeatures: !IN_PROD || user.enableExperimentalFeatures,
  };
};

/**
 * Build a list of fields to return for a resource for the editor.
 *
 * The content editor only needs a limited set of fields for a given resource, so we
 * can be explicit in API calls of which fields we want returned for the most efficient
 * API response.
 *
 * This will collect all the fields defined for editing in the `items` list for the
 * given resource, and add common fields like the ID as well as created and updated
 * timestamps.
 *
 * @param {String} resource The resource type.
 * @param {Array} items The list of items to edit for the editor container.
 * @return {String} A query string with fields defined for use in API requests.
 */
const getResourceEditorFieldsQuery = (resource, items) => {
  const fields = new Set(
    items
      .filter((item) => item.resource === resource)
      .map((item) => [`editor_${item.field}`, ...(item.usesFields ?? [])])
      .flat()
  );
  const query = new URLSearchParams();
  query.append('fields', ['id', 'created_at', 'updated_at', ...fields].join(','));
  return query.toString();
};

/**
 * Reducer for editor container state.
 *
 * Handles the main state of which data item is currently being edited, and
 * sets up sub-reducers for the field in each item that can be edited to handle
 * undo/redo.
 *
 * This reducer should be used to initialize the state in `useReducer`. In this
 * case it'll be called with no `action` argument, and we handle that as a
 * special action to set up the initial sub-reducers for each item.
 *
 * We handle the "UNDO" and "REDO" action types explicitly here to map to the
 * correct `undoable` reducer, and provide an explicit "UPDATE" action type to
 * push a new present on the current `undoable` reducer state.
 */
const reducer = (state, action) => {
  if (action === undefined) {
    return Object.assign(
      {
        currentIndex: 0,
        nextIndex: null,
        reinitialize: false,
        saveAfterReinitialize: false,
        disableUndoRedo: false,
      },
      state,
      ...state.items.map(({ field, stateKey }) => ({
        [`${stateKey}_${field}`]: undoable({
          present: getState(stateKey)[`editor_${field}`],
        }),
      }))
    );
  }

  const { field, stateKey } = state.items[action.index];
  const prefixedKey = `${stateKey}_${field}`;

  switch (action.type) {
    /*
     * Initializes a change of current item, allowing content editor to save
     * with latest content before being reinitialized with new item content.
     */
    case 'START_SET_ITEM':
      return {
        ...state,
        nextIndex: action.index,
        reinitialize: false,
        saveAfterReinitialize: false,
        saveNow: true,
      };

    /*
     * Update index of current item, which will reinitialize the editor with
     * content from the state from the new index.
     */
    case 'SET_ITEM':
      return {
        ...state,
        currentIndex: action.index,
        nextIndex: null,
        reinitialize: true,
        saveAfterReinitialize: false,
        saveNow: false,
      };

    /*
     * Updates the content for the current item, pushing it onto the undo stack.
     * Also makes sure to reset `nextIndex` since this action will be done as
     * part of the `START_SET_ITEM` and `SET_ITEM` sequence and we don't want
     * the editor to re-save in the middle of the transition.
     */
    case 'UPDATE':
      return {
        ...state,
        [prefixedKey]: undoable(state[prefixedKey], {
          newPresent: action.data[`editor_${field}`],
        }),
        nextIndex: null,
        reinitialize: false,
        saveAfterReinitialize: false,
        saveNow: false,
      };

    /*
     * Updates the content for the current item from the backend, thus
     * replacing the current present state in the undo stack.
     */
    case 'REFRESH_ITEM':
      return {
        ...state,
        [prefixedKey]: undoable({
          present: action.data[`editor_${field}`],
        }),
        reinitialize: action.index === state.currentIndex,
        saveAfterReinitialize: action.index === state.currentIndex,
        saveNow: false,
      };

    /*
     * Handle undo and redo through the undo stack for the current item,
     * reinitializing the editor with the content from the past or future.
     */
    case 'UNDO':
    case 'REDO':
      return {
        ...state,
        [prefixedKey]: undoable(state[prefixedKey], {
          type: action.type,
        }),
        reinitialize: true,
        saveAfterReinitialize: true,
        saveNow: false,
      };

    /*
     * Flags undo/redo as deactivated, usually due to the editor being in a
     * modal editing mode which needs to be resolved before we can reinitialize
     * the editor content.
     */
    case 'DISABLE_UNDOREDO':
      return {
        ...state,
        disableUndoRedo: true,
        reinitialize: false,
        saveAfterReinitialize: false,
        saveNow: false,
      };

    /*
     * Simple flags undo/redo as active again after having been disabled, see
     * above.
     */
    case 'ENABLE_UNDOREDO':
      return {
        ...state,
        disableUndoRedo: false,
        reinitialize: false,
        saveAfterReinitialize: false,
        saveNow: false,
      };

    default:
      throw new Error('Unknown state action type.');
  }
};

/**
 * <ContentEditorContainer /> component.
 *
 * We store undo states for each item in memory so that it's easy to go back
 * and forth between changes. On each save to backend we store the complete
 * HTML in the current undo state.
 */
const ContentEditorContainer = ({
  items,
  replaceHtmlSelector = null,
  children = null,
  controlsContainer = null,
  ...contentEditorProps
}) => {
  const [state, dispatch] = useReducer(reducer, { items }, reducer);
  const {
    config = {},
    dependents = [],
    field,
    resource,
    styleContent = contentEditorProps.styleContent,
    stateKey,
    updateField,
  } = items[state.currentIndex];
  const obj = getState(stateKey);
  const { past, present, future } = state[`${stateKey}_${field}`];
  const createConfig = useCallback(
    (config) => getConfig(resource, obj.id, obj.resource_url, config),
    [resource, obj.id, obj.resource_url]
  );

  const refreshDependentItems = useCallback(
    () =>
      Promise.all(
        dependents.map((index) => {
          const currentItem = items[index];
          const depObj = getState(currentItem.stateKey);
          const query = getResourceEditorFieldsQuery(currentItem.resource, items);

          return getRequest(`/${currentItem.resource}/${depObj.id}/?${query}`).then(
            (data) => {
              updateState([currentItem.stateKey], data);
              dispatch({ type: 'REFRESH_ITEM', data, index });
            }
          );
        })
      ),
    [dependents, items]
  );

  // TODO: Rewrite this as `useEffect` which differentiates whether or not to
  // push new state onto undo stack itself, rather than rely on
  // `isPartialChange` flag from `ContentEditor`. Will also be able to avoid
  // the need for the `saveAfterReinitialize` flag, I think.
  const saveHandler = useCallback(
    ({ html, isPartialChange }) => {
      if (html) {
        const query = getResourceEditorFieldsQuery(resource, items);
        return patch(`/${resource}/${obj.id}/?${query}`, { [field]: html })
          .then((newObj) => {
            updateState([stateKey], newObj);

            if (isPartialChange) {
              dispatch({
                type: 'UPDATE',
                data: newObj,
                index: state.currentIndex,
              });

              // Update local state for other items dependent on the same state key
              items.forEach((otherItem, index) => {
                if (index !== state.currentIndex && otherItem.stateKey === stateKey) {
                  dispatch({
                    type: 'REFRESH_ITEM',
                    data: newObj,
                    index,
                  });
                }
              });
            }

            // Refresh dependent items after successful patch, before we update
            // our component state and potentially switch to a dependent item.
            return refreshDependentItems();
          })
          .then(() => {
            if (state.nextIndex !== null) {
              dispatch({ type: 'SET_ITEM', index: state.nextIndex });
            }
          });
      }

      return Promise.resolve();
    },
    [
      resource,
      obj.id,
      field,
      stateKey,
      refreshDependentItems,
      state.currentIndex,
      state.nextIndex,
      items,
    ]
  );
  const i18n = useContext(TranslationsContext);
  const { onSettingsChanged } = contentEditorProps;

  Object.assign(contentEditorProps, {
    // Merge provided config with resource specific config for uploading etc.
    config: createConfig({
      ...(typeof config === 'function' ? config(obj) : config),
      ...contentEditorProps.config,
    }),
    styleContent:
      (typeof styleContent === 'function' ? styleContent(obj) : styleContent) ?? '',
    // Adds settings changes in editor with current object that is editing
    onSettingsChanged:
      onSettingsChanged && ((settings) => onSettingsChanged({ obj, settings })),
  });

  const classNames = ['grid-x', 'grid-padding-x', 'align-middle', 'border-bottom'];

  if (!controlsContainer) {
    classNames.push('padding-top-small');
  }
  if (children) {
    classNames.push('padding-bottom-small');
  }

  // Activate tab on specified event from content editor and scrolls to top
  items.forEach((item, index) => {
    if (item.activateOnEvent) {
      contentEditorProps[item.activateOnEvent] = () => {
        dispatch({ type: 'START_SET_ITEM', index });
        window.scrollTo(0, 0);
      };
    }
  });

  // TODO: Icon component needs updating for sizing/HTML, and change undo/redo icons.
  const toolbar = (
    <div className={classNames.join(' ')} key="tabs-wrapper">
      {children && <div className="cell auto">{children}</div>}
      {items.length > 1 && (
        <div className="cell large-offset-4 auto">
          <div className="flex-container align-center">
            <Tab
              update
              items={items.map((item, index) => [index, item.label])}
              onItemClick={(index) => {
                if (state.nextIndex === null && index !== state.currentIndex) {
                  dispatch({ type: 'START_SET_ITEM', index });
                  window.scrollTo(0, 0);
                }
              }}
              selectedItem={state.currentIndex}
            />
          </div>
        </div>
      )}
      <div
        className={`cell flex-container align-middle align-right ${
          items.length > 1 ? 'small-6 large-4' : 'shrink'
        }`}
      >
        <div className="show-for-medium padding-right">
          <SavedStatus lastSaved={obj[updateField]} />
        </div>
        <div>
          <button
            type="button"
            className="button secondary icon"
            onClick={() => dispatch({ type: 'UNDO', index: state.currentIndex })}
            disabled={state.disableUndoRedo || past?.length === 0}
            title={i18n.gettext('Undo')}
          >
            <Icon name="freehand/text-options-undo" />
          </button>{' '}
          <button
            type="button"
            className="button secondary icon"
            onClick={() => dispatch({ type: 'REDO', index: state.currentIndex })}
            disabled={state.disableUndoRedo || future?.length === 0}
            title={i18n.gettext('Redo')}
          >
            <Icon name="freehand/text-options-redo" />
          </button>
        </div>
      </div>
    </div>
  );

  return (
    <>
      {controlsContainer ? ReactDOM.createPortal(toolbar, controlsContainer) : toolbar}
      <ContentEditor
        key="content-editor"
        html={present}
        onModalEnter={() =>
          dispatch({ type: 'DISABLE_UNDOREDO', index: state.currentIndex })
        }
        onModalExit={() =>
          dispatch({ type: 'ENABLE_UNDOREDO', index: state.currentIndex })
        }
        replaceHtmlSelector={replaceHtmlSelector}
        saveHandler={saveHandler}
        reinitialize={state.reinitialize}
        saveAfterReinitialize={state.saveAfterReinitialize}
        saveNow={state.saveNow}
        {...contentEditorProps}
      />
    </>
  );
};

ContentEditorContainer.propTypes = {
  children: PropTypes.node,
  controlsContainer: PropTypes.instanceOf(Element),
  items: PropTypes.arrayOf(
    PropTypes.shape({
      resource: PropTypes.string.isRequired,
      stateKey: PropTypes.string.isRequired,
      field: PropTypes.string.isRequired,
      label: PropTypes.string,
      updateField: PropTypes.string,
      activateOnEvent: PropTypes.string,
    })
  ).isRequired,
  replaceHtmlSelector: PropTypes.string,
};

export default ContentEditorContainer;
