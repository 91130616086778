import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';

import Icon from 'common/lib/components/icon';
import LoadingIndicator from 'common/lib/components/loading-indicator';

import { STATUS_LOADING, STATUS_LOADED, STATUS_ERROR } from '../constants';
import { BrowserContext } from '../contexts';
import CloseButton from './CloseButton';
import ContentItem from './ContentItem';
import Filter from './Filter';
import Message from './Message';
import Ordering from './Ordering';
import RefreshButton from './RefreshButton';
import IntegrationFilter from './IntegrationFilter';
import i18n from '../i18n';
import { ContentItemType, FilterType, IntegrationSettingsType } from '../types';
import '../scss/browser.scss';

const QUERY_DELAY = 300;
const DEFAULT_ORDER = '-updated_at';

/**
 * The Browser component.
 */
const Browser = ({ filter, isRefreshing, items, settings, status }) => {
  const context = useContext(BrowserContext);
  const classNames = [
    'grid-x',
    'grid-padding-x',
    'grid-padding-y',
    'small-up-2',
    'medium-up-3',
    'large-up-4',
    'no-bullet',
  ];
  const [query, setQuery] = useState(filter.query || null);

  useEffect(() => {
    if (query === null || query.length === 1) {
      return;
    }
    const timeout = setTimeout(() => context.updateFilter({ query }), QUERY_DELAY);
    return () => clearTimeout(timeout);
  }, [context, query]);

  return (
    <main>
      <header className="grid-container border-bottom padding-y">
        <div className="grid-x grid-padding-x grid-padding-y align-middle">
          <div className="cell shrink">
            <Icon name="freehand/folder-open" classNames="large" />
          </div>
          <div className="cell auto">
            <h2>{i18n.gettext('Content Browser')}</h2>
          </div>
          <div className="cell shrink">
            <div className="button-group">
              <RefreshButton disabled={isRefreshing} integration={filter.integration} />
              <CloseButton />
            </div>
          </div>
        </div>
      </header>
      <section className="grid-container">
        <div className="grid-x grid-padding-x grid-padding-y">
          <div className="cell">
            <div className="grid-x grid-padding-x align-middle">
              <div className="cell auto">
                <input
                  placeholder={i18n.gettext('Search')}
                  type="text"
                  value={query || ''}
                  onChange={(event) => {
                    setQuery(event.target.value);
                  }}
                  disabled={isRefreshing}
                />
              </div>
              <div className="cell shrink">
                <Filter
                  key={filter.integration}
                  current={filter}
                  settings={settings.integrations.find(
                    (obj) => obj.id === filter.integration
                  )}
                  disabled={isRefreshing}
                />{' '}
                <Ordering
                  key={filter.order}
                  current={filter?.order ?? DEFAULT_ORDER}
                  disabled={isRefreshing}
                />{' '}
                <IntegrationFilter
                  current={filter.integration}
                  integrations={settings.integrations}
                  disabled={isRefreshing}
                />
              </div>
            </div>
          </div>
          <div className="cell">
            {status === STATUS_LOADING && (
              <div className="flex-container align-center-middle">
                <LoadingIndicator
                  messages={[
                    i18n.gettext('Still loading…'),
                    i18n.gettext('Thanks for your patience!'),
                    i18n.gettext('Almost there…'),
                  ]}
                />
              </div>
            )}
            {status === STATUS_ERROR && (
              <Message
                type="alert"
                message={i18n.gettext('An error occurred while loading the content.')}
              />
            )}
            {status === STATUS_LOADED && items.length === 0 && (
              <Message
                type="warning"
                message={
                  isRefreshing
                    ? i18n.gettext('Refreshing content…')
                    : i18n.gettext('No content found. Try to refresh content.')
                }
              />
            )}
            {status === STATUS_LOADED && items.length > 0 && (
              <ol className={classNames.join(' ')}>
                {items.map((item, index) => {
                  return (
                    <ContentItem
                      key={index}
                      item={item}
                      integration={filter.integration}
                    />
                  );
                })}
              </ol>
            )}
          </div>
        </div>
      </section>
    </main>
  );
};

Browser.propTypes = {
  filter: FilterType,
  isRefreshing: PropTypes.bool.isRequired,
  items: PropTypes.arrayOf(ContentItemType),
  settings: PropTypes.shape({
    integrations: PropTypes.arrayOf(IntegrationSettingsType).isRequired,
  }),
  status: PropTypes.string.isRequired,
};

export default Browser;
