import PropTypes from 'prop-types';
import { useContext, useRef, useState } from 'react';
import {
  Controller,
  useForm,
  FormProvider,
  useFieldArray,
  useFormContext,
} from 'react-hook-form';

import Icon from 'common/lib/components/icon.js';
import { useFormConfig } from '../hooks';
import ChecklistItem from '../../components/checklist-item';
import { RadioField, StringField } from '../../components/fields';
import Select from '../../components/select';
import { TranslationsContext } from '../../components/translate';
import { get as getState } from '../../state';
import { patch } from '../../utils/api';

/**
 * <WebsiteChecklistItem />
 *
 * A React component for specifying the domain where the popup has permissions to load.
 */
const WebsiteChecklistItem = ({ name, value = '', ...checklistItemProps }) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const errorMessage = errors?.[name]?.message;
  const i18n = useContext(TranslationsContext);

  return (
    <ChecklistItem
      title={i18n.gettext('Website')}
      description={
        value
          ? i18n.sprintf(
              i18n.gettext('This popup is permitted to load on <em>%(hostname)s</em>.'),
              {
                hostname: value,
              }
            )
          : `<em>${i18n.gettext('Not set')}</em>`
      }
      isValid={!errorMessage && value !== ''}
      {...checklistItemProps}
    >
      <StringField
        label={i18n.gettext(
          'Specify the domain where the popup has permissions to load.'
        )}
        inputProps={register(name, {
          value,
        })}
        isRequired={true}
        isErrorVisible={!!errorMessage}
        errorMessage={errorMessage}
      />
    </ChecklistItem>
  );
};

WebsiteChecklistItem.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
};

/**
 * <PathChecklistItem />
 *
 * A React component for specifying a path that restricts the popup to only be seen on
 * that specific page.
 */
const PathChecklistItem = ({ name, value = [], isOpen, ...checklistItemProps }) => {
  const i18n = useContext(TranslationsContext);
  const matcherOptions = [
    {
      label: i18n.gettext('Matches'),
      value: 'exact',
    },
    {
      label: i18n.gettext('Starts With'),
      value: 'starts_with',
    },
    {
      label: i18n.gettext('Contains'),
      value: 'contains',
    },
  ];
  const newPath = { value: '', matcher: 'exact' };
  const {
    control,
    register,
    formState: { errors },
  } = useFormContext();
  const err = errors?.[name] ?? {};
  const { fields, insert, remove } = useFieldArray({
    control,
    name,
  });
  const description =
    value.length > 0
      ? i18n.gettext('This popup is only visible on one or more specific pages.')
      : i18n.gettext('This popup will be visible on all pages.');

  return (
    <ChecklistItem
      title={i18n.gettext('Specific web pages')}
      description={value.length == 0 ? <em>{description}</em> : description}
      isOpen={isOpen}
      isValid={value.length > 0 ? true : null}
      {...checklistItemProps}
    >
      <span>
        {i18n.gettext(
          'Define URL rules for which pages the popup will be visible at. Leave ' +
            'this empty to display the popup on all pages.'
        )}
      </span>
      {fields.map((field, index) => (
        <div
          key={`path-${index}`}
          className="grid-x grid-padding-x align-top padding-top"
        >
          <div className="cell small-4">
            <Controller
              name={`${name}[${index}].matcher`}
              control={control}
              value={field.matcher}
              render={({ field }) => (
                <Select
                  name={field.name}
                  selectedValue={field.value}
                  onChange={field.onChange}
                  options={matcherOptions.map(({ label, value }) => [value, label])}
                  aria-labelledby="matcher"
                />
              )}
            />
          </div>
          <div className="cell auto">
            <StringField
              inputProps={{
                ...{ placeholder: i18n.gettext('I.e. /blog') },
                ...register(`${name}[${index}].value`, {
                  value: field.value,
                }),
              }}
              isRequired={false}
              isErrorVisible={!!err?.[index]?.value}
              errorMessage={err?.[index]?.value?.message}
              aria-labelledby="path"
            />
          </div>
          <div className="cell shrink">
            <button
              type="button"
              className="button secondary small icon"
              onClick={() => remove(index)}
              disabled={fields.length <= 1}
            >
              <Icon name="freehand/remove-subtract-circle-bold" />
            </button>{' '}
            <button
              type="button"
              className="button secondary small icon"
              onClick={() => insert(index + 1, { ...newPath })}
            >
              <Icon name="freehand/add-circle-bold" />
            </button>
          </div>
        </div>
      ))}
    </ChecklistItem>
  );
};

PathChecklistItem.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      matcher: PropTypes.string,
    })
  ),
  isOpen: PropTypes.bool,
};

/**
 * <SubscriberStatusItem />
 *
 * A React component for specifying if the popup should be hidden or shown depending
 * on the subscribers on a newsletter.
 */
const SubscriberStatusItem = ({
  name,
  value,
  isOpen,
  onOpen,
  onClose,
  onSave,
  ...checklistItemProps
}) => {
  const i18n = useContext(TranslationsContext);
  const { register } = useFormContext();
  const description = !value
    ? i18n.gettext(
        'This popup is displayed regardless of whether the visitors are already ' +
          'subscribed or not.'
      )
    : value?.match === 'hide'
      ? i18n.sprintf(
          i18n.gettext(
            'This popup is hidden for those who have already subscribed as ' +
              'identified by the query parameter %(query)s being present in the URL.'
          ),
          { query: `<span class="label secondary">${value.query}</span>` }
        )
      : value?.match === 'show'
        ? i18n.sprintf(
            i18n.gettext(
              'This popup is shown only to those who have already subscribed as ' +
                'identified by the query parameter %(query)s being present in the URL.'
            ),
            { query: `<span class="label secondary">${value.query}</span>` }
          )
        : '';

  return (
    <ChecklistItem
      title={i18n.gettext('Newsletter subscribers')}
      description={!value ? <em>{description}</em> : description}
      isValid={!!value || null}
      switchEnabled={!!value || isOpen}
      toggleSwitch={(isOn) => {
        if (isOn) {
          onOpen();
        } else {
          onClose();
          if (value !== null) onSave({ [name]: null });
        }
      }}
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      {...checklistItemProps}
    >
      <p className="padding-top">
        {i18n.gettext('Show or hide the popup only to those who are subscribed:')}
      </p>
      <RadioField
        label={i18n.gettext('Show the popup')}
        inputProps={{ ...register(name), value: 'show' }}
      />
      <RadioField
        label={i18n.gettext('Hide the popup')}
        inputProps={{ ...register(name), value: 'hide' }}
      />
    </ChecklistItem>
  );
};

SubscriberStatusItem.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onOpen: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.any,
};

/**
 * <TargetingChecklist />
 *
 * Combines website, paths checklist item components for form popups.
 */
const TargetingChecklist = () => {
  const [isSaving, setIsSaving] = useState(false);
  const { data, updateForm, updateData } = useFormConfig(getState('popupform'), [
    'website',
    'paths',
    'subscriber',
  ]);
  const { id, current } = data;
  const formRef = useRef(null);
  const methods = useForm({
    values: {
      name: data.name ?? null,
      paths: data.paths?.length > 0 ? data.paths : [{ value: '', matcher: 'exact' }],
      subscriber: data.subscriber?.match === 'show' ? 'show' : 'hide',
    },
  });

  const handleOpen = (current) => {
    updateData({ current });
  };

  const handleClose = () => {
    methods.reset();
    updateData({
      current: null,
    });
  };

  /**
   * Handle and update errors resulting from the API request.
   *
   * @param {Object} result  errors response object.
   */
  const handleErrors = (result) => {
    const currentError = result?.errors?.config?.[current] || result?.errors?.[current];

    if (current === 'website' && currentError?.[0]) {
      methods.setError(current, {
        type: 'server',
        message: currentError?.[0],
      });
    }

    if (current === 'paths' && currentError?.[0]) {
      Object.keys(currentError?.[0]).forEach((key) => {
        methods.setError(`${current}[${key}].value`, {
          type: 'server',
          message: currentError?.[0][key],
        });
      });
    }
  };

  /**
   * Proceed with the form submission by calling the form's submit method if the event
   * triggering the submission is a CustomEvent.
   *
   * React Hook Form controls the submit event and will prevent further handling of it.
   * This function is used to ensure that when the base steps controller triggers a
   * submit event using a `CustomEvent`, that we invoke an actual form submit.
   *
   * @param {Event} event The event object that triggered the submission.
   */
  const proceed = (event) => {
    if (event?.nativeEvent.constructor === CustomEvent) {
      const form = formRef.current;
      form.submit();
    }
  };

  /**
   * Handles form configuration submission to the API and error handling.
   *
   * @param {Object} config - The configuration data to be sent to the API.
   * @param {Event} event The event that triggered the form submission.
   */
  const handleSave = async (config, event) => {
    if (Object.keys(config).length <= 0) {
      return;
    }

    try {
      setIsSaving(true);
      methods.clearErrors();
      const obj = await patch(`/forms/${id}/`, { config });
      updateForm(obj);
      if (event) {
        handleClose();
        proceed(event);
      }
    } catch (result) {
      handleErrors(result);
    } finally {
      setIsSaving(false);
    }
  };

  /**
   * Save form data through a patch request to the API.
   *
   * Depending on which checklist item that is open we build the data structure that is
   * sent to the API. If any error occurs we store it in the state so that it can be
   * displayed.
   *
   * @param {Object} formObj The form data object managed by React Hook Form.
   * @param {Event} event The event that triggered the form submission.
   */
  const onSubmit = async (formObj, event) => {
    let value = formObj[current] ?? null;

    if (!current) {
      proceed(event);
      return;
    }

    // If paths value has only one input field and it's empty,
    // we save an empty value to indicate that it's intentionally cleared. This allows
    // users to clear all values while still keeping the option to add a path.
    if (current === 'paths' && value.length === 1 && !value[0].value) {
      value = [];
    }

    handleSave({ [current]: value }, event);
  };

  const checklistItemProps = (key) => ({
    isOpen: current === key,
    isSaving,
    name: key,
    value: data[key],
    onOpen: () => handleOpen(key),
    onClose: () => handleClose(true),
    onSave: (config) => handleSave(config, null),
  });

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(onSubmit)}
        method="post"
        noValidate
        ref={formRef}
      >
        <input type="hidden" name="next" />
        <WebsiteChecklistItem {...checklistItemProps('website')} />
        <PathChecklistItem {...checklistItemProps('paths')} />
        <SubscriberStatusItem {...checklistItemProps('subscriber')} />
      </form>
    </FormProvider>
  );
};

export default TargetingChecklist;
