import LinkifyIt from 'linkify-it';
import { FC, useContext, useState } from 'react';
import { createRoot } from 'react-dom/client';
import { count } from 'sms-length';

import { Translations, TranslationsContext } from '../../components/translate';
import Tooltip from '../../components/tooltip';

type Personalization = Record<
  string,
  {
    label: string;
    fields: [string, string][];
  }
>;

interface SMSEditorProps {
  text: string;
  unsubscribeLink: string;
}

interface State {
  plain: string;
  unsubscribe_link: string;
  personalization: Personalization;
}

/**
 * Calculates the number of characters and messages in the given text,
 * replacing any detected URLs with a predefined placeholder.
 *
 * @param text The text to analyze.
 * @param unsubscribeLink The unsubscribe link to exclude from the character count.
 * @returns An object containing character and message count statistics.
 */
const smsStats = (text: string, unsubscribeLink: string) => {
  let shortenedText = text;
  const urls = new LinkifyIt().match(text);

  if (urls) {
    shortenedText = urls
      .filter(
        (match) =>
          match.text !== unsubscribeLink && URL.parse(match.url)?.pathname !== '/'
      )
      .reduce((currentText: string, match) => {
        return currentText.replace(match.text, 'sms.mailmojo.no/abc1234');
      }, text);
  }

  return count(shortenedText);
};

const SMSEditor: FC<SMSEditorProps> = ({ text, unsubscribeLink }) => {
  const [value, setValue] = useState(text ?? '');
  const i18n = useContext(TranslationsContext);
  const { length, messages } = smsStats(value, unsubscribeLink);

  return (
    <div className="grid-x grid-padding-x grid-padding-y align-center">
      <div className="cell large-7">
        <textarea
          className="input"
          name="plain"
          rows={12}
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
        <div className="padding-top float-right">
          <div className="label basic">
            {i18n.sprintf(
              i18n.ngettext('%(num)d character', '%(num)d characters', length),
              {
                num: length,
              }
            )}
          </div>
          <div className="label basic">
            {i18n.sprintf(
              i18n.ngettext('%(num)d message', '%(num)d messages', messages),
              {
                num: messages,
              }
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const PersonalizationList: FC<{ personalization: Personalization }> = ({
  personalization,
}) => {
  const i18n = useContext(TranslationsContext);
  const example = i18n.gettext('$(firstname or there)');
  return (
    <>
      <h2 className="h4">{i18n.gettext('Personalization')}</h2>
      <p className="padding-bottom-small">
        {i18n.gettext(
          'Personalize your message by adding the following variables. Copy and ' +
            'paste them into your message.'
        )}
      </p>
      <ul className="no-bullet">
        {personalization.contact_fields.fields.map(([fieldName, fieldLabel]) => (
          <li key={fieldLabel} className="padding-bottom-small">
            <Tooltip title={fieldName}>
              <span className="label secondary">$({fieldLabel})</span>
            </Tooltip>
          </li>
        ))}
      </ul>
      <p
        className="font-size-small"
        dangerouslySetInnerHTML={{
          __html: i18n.sprintf(
            i18n.gettext(
              'Personalization variables accept fallbacks: %(example)s shows ' +
                'first name if available, otherwise "there".'
            ),
            {
              example: `<span class="label">${example}</span>`,
            }
          ),
        }}
      />
    </>
  );
};

/**
 * SMS Content editor step controller.
 */
const SmsContentStep = {
  init(container: HTMLElement, state: State) {
    const { plain, unsubscribe_link: unsubscribeLink, personalization } = state;

    const root = createRoot(container);
    root.render(
      <Translations>
        <section
          className={[
            'grid-x',
            'grid-padding-x',
            'grid-padding-y',
            'flex-child-grow',
            'border-top',
          ].join(' ')}
        >
          <div className="cell large-2 medium-3">
            <PersonalizationList personalization={personalization} />
          </div>
          <div
            className="cell large-10 medium-9"
            style={{ backgroundColor: '#f2f0df' }}
          >
            <input type="hidden" name="next" />
            <SMSEditor
              text={plain.replaceAll('\r', '')}
              unsubscribeLink={unsubscribeLink}
            />
          </div>
        </section>
      </Translations>
    );
  },
};

export default SmsContentStep;
