import { useContext, useState } from 'react';
import Icon from 'common/lib/components/icon';
import { BrowserContext } from '../contexts';

/**
 * Stateless component for rendering a button to trigger a refresh of an item.
 */
const RefreshItemButton = ({ integration, item }) => {
  const context = useContext(BrowserContext);
  const [disabled, setDisabled] = useState(false);
  return (
    <button
      className="button secondary compact refresh-item-button"
      type="button"
      disabled={disabled}
      onClick={() => {
        setDisabled(true);
        context.triggerSync(integration, item.source_id).then(() => {
          setDisabled(false);
        });
      }}
    >
      <Icon name="ui-bold/interface-synchronize-arrow" />
    </button>
  );
};

export default RefreshItemButton;
