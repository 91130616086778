import PropTypes from 'prop-types';
import { useContext } from 'react';

import ChecklistItem from '../../components/checklist-item';
import { StringField } from '../../components/fields';
import { TranslationsContext } from '../../components/translate';

/*
 * <PreviewTextChecklistItem /> component.
 *
 * A component for editing the preview text of a newsletter.
 */
const PreviewTextChecklistItem = ({
  value = '',
  error = null,
  ...checklistItemProps
}) => {
  const i18n = useContext(TranslationsContext);
  return (
    <ChecklistItem
      title={i18n.gettext('Preview text')}
      description={
        value ? (
          <em>{value}</em>
        ) : (
          i18n.gettext(
            'A short sentence that is displayed after the subject in most inboxes.'
          )
        )
      }
      isValid={!!value || null}
      {...checklistItemProps}
    >
      <StringField
        inputProps={{ name: 'preview_text', defaultValue: value }}
        isRequired
        isErrorVisible={!!error}
        errorMessage={error}
      />
    </ChecklistItem>
  );
};

PreviewTextChecklistItem.propTypes = {
  value: PropTypes.string,
  error: PropTypes.string,
};

export default PreviewTextChecklistItem;
