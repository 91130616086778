/* eslint-disable import/extensions, no-new,no-underscore-dangle, react/forbid-prop-types */
import uniqueId from 'lodash/uniqueId';
import PropTypes from 'prop-types';
import { createRef, Component } from 'react';
import { createRoot } from 'react-dom/client';
import { Dropdown } from 'foundation-sites/js/foundation.dropdown';
import Icon from 'common/lib/components/icon';
import translate from './translate';

const jQuery = window.jQuery;
const isWhite = (color) => color === '#fff' || color === '#ffffff';
const defaultSize = 24;

/**
 * <ColorIcon />
 *
 * Renders an icon with given color, size and optional icon.
 */
const ColorIcon = ({ color, icon = null, size = 24, style = {} }) => (
  <span
    className={`rounded flex-container align-center align-middle ${
      isWhite(color) && size >= defaultSize ? 'bordered' : ''
    }`}
    style={{
      backgroundColor: color,
      width: `${size}px`,
      height: `${size}px`,
      ...style,
    }}
  >
    {icon && <Icon name={icon} classNames="bold tiny" />}
  </span>
);

ColorIcon.propTypes = {
  color: PropTypes.string.isRequired,
  icon: PropTypes.string,
  size: PropTypes.number,
  style: PropTypes.object,
};

/**
 * <ColorPicker />
 *
 * Renders a button with a color icon inside. Clicking on the button opens up a Foundation
 * dropdown pane with given set of colors that are selectable.
 */
class ColorPicker extends Component {
  static propTypes = {
    selected: PropTypes.string.isRequired,
    colors: PropTypes.arrayOf(PropTypes.string).isRequired,
    onSelect: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
      gettext: PropTypes.func.isRequired,
    }).isRequired,
    buttonClasses: PropTypes.string,
    viewportSize: PropTypes.string.isRequired,
  };

  static defaultProps = {
    buttonClasses: '',
  };

  constructor(props) {
    super(props);
    this.pane = createRef();
    this.paneId = uniqueId();
    this.dropdown = null;

    const { selected } = this.props;

    this.state = {
      selected,
    };
  }

  componentDidMount() {
    this.dropdown = new Dropdown(jQuery(this.pane.current), {
      closeOnClick: true,
      position: 'top',
      alignment: 'center',
      vOffset: 4,
    });
  }

  componentWillUnmount() {
    if (this.dropdown) {
      this.dropdown._destroy();
      this.dropdown = null;
    }
  }

  setColor = (color) => {
    const { onSelect } = this.props;

    this.setState({ selected: color });
    onSelect(color);
  };

  render() {
    const { buttonClasses, colors, i18n } = this.props;
    const { selected } = this.state;

    return (
      <div className="color-picker flex-container align-center padding-top-small">
        <button
          type="button"
          data-toggle={`color-picker-${this.paneId}`}
          className={`button secondary ${buttonClasses}`}
        >
          <span className="flex-container align-middle align-center">
            <ColorIcon color={selected} size={12} />
            <span className="padding-left-small">{i18n.gettext('Palette')}</span>
          </span>
        </button>
        <div
          id={`color-picker-${this.paneId}`}
          className="dropdown-pane"
          ref={this.pane}
          style={{ width: 'auto' }}
        >
          <div className="flex-container" style={{ margin: '8px' }}>
            {colors.map((color) => (
              <button
                key={color}
                type="button"
                className={`padding-x-small padding-y-small ${
                  selected === color ? 'bg-highlight border-radius' : ''
                }`}
                style={{ cursor: 'pointer' }}
                onClick={() => this.setColor(color)}
              >
                <ColorIcon color={color} />
              </button>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

const TranslatableColorPicker = translate()(ColorPicker);

const renderColorPicker = (container, options) => {
  const root = createRoot(container);
  root.render(<TranslatableColorPicker {...options} />);
};

export default TranslatableColorPicker;

export { renderColorPicker };
