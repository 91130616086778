import PropTypes from 'prop-types';
import { i18nType } from './translate';

const Callout = ({ type, children }) => (
  <div className="padding-top">
    <div className={`callout ${type}`}>{children}</div>
  </div>
);
Callout.propTypes = {
  type: PropTypes.string.isRequired,
  children: PropTypes.instanceOf(Object).isRequired,
};

const SenderDomainStatus = ({ id, domain, domainStatus = null, i18n, source }) => {
  if (domainStatus.isFree) {
    return (
      <Callout type="alert">
        <p
          className="padding-bottom-small"
          dangerouslySetInnerHTML={{
            __html: i18n.sprintf(
              i18n.gettext(
                'You are using an email address from %(domain)s, which is a shared ' +
                  'email domain. To ensure high deliverability of your newsletters, ' +
                  'you must use an email address on a domain you own and set up ' +
                  'authentication.'
              ),
              { domain: `<em>${domain}</em>` }
            ),
          }}
        />
        <p>
          <a className="button small primary" href="/account/domains/">
            {i18n.gettext('Add and verify your domain')}
          </a>
        </p>
      </Callout>
    );
  }

  // This is only shown after you've created a new account and haven't verified
  // your email yet.
  if (!domainStatus.isVerified) {
    return (
      <Callout type="alert">
        <p className="padding-bottom-small">
          {i18n.gettext(
            "You are using an email address on a domain that hasn't been verified yet."
          )}
        </p>
        <p>
          <a className="button small primary" href="/account/domains/">
            {i18n.gettext('Verify your domain')}
          </a>
        </p>
      </Callout>
    );
  }

  if (!domainStatus.isValid) {
    return (
      <Callout type={!domainStatus.isAuthenticated ? 'alert' : 'warning'}>
        <p
          className="padding-bottom-small"
          dangerouslySetInnerHTML={{
            __html: i18n.sprintf(
              !domainStatus.isAuthenticated
                ? source === 'automation'
                  ? i18n.gettext(
                      'Authentication of your sender domain %(domain)s is not ' +
                        'set up.'
                    )
                  : i18n.gettext(
                      'Authentication of your sender domain %(domain)s is not ' +
                        'set up. Without it, your newsletters are unlikely to be ' +
                        'delivered to all recipients and will be spam filtered to a ' +
                        'much higher degree.'
                    )
                : i18n.gettext(
                    'A valid DMARC policy has not been set up for your sender domain ' +
                      '%(domain)s. We strongly recommend setting this up for the ' +
                      'best deliverability.'
                  ),
              { domain: `<em>${domain}</em>` }
            ),
          }}
        />
        <p>
          <a
            className="button small primary"
            href={`/account/domains/${id}/authenticate/`}
          >
            {i18n.gettext('Authenticate your domain')}
          </a>
        </p>
      </Callout>
    );
  }

  return null;
};

SenderDomainStatus.propTypes = {
  id: PropTypes.number,
  domain: PropTypes.string.isRequired,
  domainStatus: PropTypes.shape({
    isFree: PropTypes.bool,
    isValid: PropTypes.bool,
    isVerified: PropTypes.bool,
    isAuthenticated: PropTypes.bool,
  }),
  i18n: i18nType,
  source: PropTypes.string,
};

export default SenderDomainStatus;
