import { useContext } from 'react';
import Tooltip from './tooltip';
import Gauge from './gauge';
import { TranslationsContext } from './translate';
import { formatNumber } from '../i18n';

interface StatusProps {
  currentValue: number;
  maxValue: number;
  displayCapacity?: boolean;
  toolTipText: string;
  description: string;
  inverse?: boolean;
}

/**
 * Status Component
 * A component for displaying a gauge with a tooltip and description.
 */
const Status: React.FC<StatusProps> = ({
  currentValue,
  maxValue,
  displayCapacity = false,
  toolTipText,
  description,
  inverse = false,
}) => {
  const i18n = useContext(TranslationsContext);
  const capacity = Math.round((currentValue / maxValue) * 100);

  return (
    <div className="contacts-status">
      <Tooltip title={toolTipText}>
        <Gauge
          percent={Math.min(capacity, 100)}
          label={displayCapacity ? `${capacity}%` : formatNumber(currentValue, 0)}
          description={i18n.gettext(description)}
          inverse={inverse}
        />
      </Tooltip>
    </div>
  );
};

export default Status;
