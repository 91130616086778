import React, { useContext } from 'react';

import ChecklistItem from '../../components/checklist-item';
import { Status } from '../../common/constants';
import { TranslationsContext } from '../../components/translate';

interface SMSCreditsChecklistItemProps {
  creditsRequired: number;
  currentCredits: number;
  checklistItemProps: object;
}

const SMSCreditsChecklistItem: React.FC<SMSCreditsChecklistItemProps> = ({
  creditsRequired,
  currentCredits,
  ...checklistItemProps
}) => {
  const i18n = useContext(TranslationsContext);
  const descriptionText =
    i18n.sprintf(
      i18n.ngettext(
        'This SMS campaign requires a minimum of %(num)d token to send. ' +
          'The estimate takes variable personalization content into account.',
        'This SMS campaign requires a minimum of %(num)d tokens to send. ' +
          'The estimate takes variable personalization content into account.',
        creditsRequired
      ),
      { num: creditsRequired }
    ) +
    ' ' +
    i18n.sprintf(
      i18n.ngettext(
        'You currently have %(num)d token.',
        'You currently have %(num)d tokens.',
        currentCredits
      ),
      { num: currentCredits }
    );

  return (
    <ChecklistItem
      title={i18n.gettext('Tokens')}
      status={currentCredits >= creditsRequired ? Status.VALID : Status.INVALID}
      description={<p>{descriptionText}</p>}
      {...checklistItemProps}
    />
  );
};

export default SMSCreditsChecklistItem;
