import { useContext } from 'react';
import { createRoot } from 'react-dom/client';
import { Translations, TranslationsContext } from './translate';
import Status from './status';
import { formatNumber } from '../i18n';

interface CreditsStatusProps {
  creditsCapacity: number;
}

/**
 * Get the credits tier for a given number of credits.
 *
 * @param {number} currentCredit
 * @returns {number}
 */
const getCreditsTier = (currentCredit: number): number => {
  const tiers = [500, 1000, 2500, 5000, 10000, 25000, 50000];
  return tiers.find((tier) => currentCredit <= tier) ?? tiers[tiers.length - 1];
};

/**
 * CreditsStatus React component.
 *
 * @param {CreditsStatusProps} props
 */
const CreditsStatus: React.FC<CreditsStatusProps> = ({ creditsCapacity }) => {
  const i18n = useContext(TranslationsContext);
  const toolTipText = i18n.sprintf(
    i18n.gettext('You have %(num_credits)s tokens available.'),
    {
      num_credits: formatNumber(creditsCapacity, 0),
    }
  );

  return (
    <Status
      currentValue={creditsCapacity}
      maxValue={getCreditsTier(creditsCapacity)}
      toolTipText={toolTipText}
      description={i18n.gettext('Tokens').toLowerCase()}
      displayCapacity={false}
      inverse={true}
    />
  );
};

/**
 * Setup the Credits Status React component.
 *
 * @param {HTMLElement} container
 */
const createCreditsStatus = (container: HTMLElement) => {
  const render = (user: { creditsCapacity: number }) => {
    const root = createRoot(container);
    root.render(
      <Translations>
        <CreditsStatus creditsCapacity={user.creditsCapacity} />
      </Translations>
    );
  };

  if (window.MailMojo.User) {
    render(window.MailMojo.User);
  }
};

export default CreditsStatus;

export { createCreditsStatus };
