import Icon from 'common/lib/components/icon';

import { Status } from '../common/constants';

interface ChecklistItemIconProps {
  isValid?: boolean | null;
  status?: (typeof Status)[keyof typeof Status] | null;
}

/**
 * <ChecklistItemIcon /> component.
 *
 * A stateless component for rendering validation state as an icon. This icon
 * has three possible states for `isValid`: true is green, false is red and
 * null is gray.
 *
 * @param {Boolean} props.isValid
 * @return {ReactElement}
 */
const ChecklistItemIcon: React.FC<ChecklistItemIconProps> = ({
  isValid = null,
  status = null,
}) => {
  const classes = ['icon', 'medium'];
  let name = 'freehand/form-validation-check-square';

  if (status === Status.VALID || isValid === true) {
    classes.push('success');
  } else if (status === Status.WARNING) {
    classes.push('warning');
    name = 'freehand/alerts-warning-triangle';
  } else if (status === Status.INFO) {
    classes.push('help');
    name = 'freehand/alerts-information-circle';
  } else if (status === Status.INVALID || isValid === false) {
    classes.push('alert');
    name = 'freehand/form-validation-remove-square';
  } else {
    classes.push('dark-gray');
  }

  return <Icon name={name} classNames={classes.join(' ')} />;
};

export default ChecklistItemIcon;
