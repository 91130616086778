/* eslint-disable import/prefer-default-export */
import { useContext, useEffect, useRef, useState } from 'react';
import { createRoot } from 'react-dom/client';
import PropTypes from 'prop-types';

import createEditorConfig, { removedPlugins } from '../../common/editors';
import { useInput } from '../../common/hooks';
import { addAlertMessage, clearMessages } from '../../common/messages';
import ContentEditorContainer from '../../components/content-editor-container';
import { Translations, TranslationsContext } from '../../components/translate';
import { patch } from '../../utils/api';
import updateState, { get as getState } from '../../state';

const User = window.MailMojo?.User;

/**
 * Update settings for social links and logo changes.
 */
const onSettingsChanged = ({ obj, settings }) => {
  const { social, account_logo, template_logo } = settings;

  if (social) {
    return patch(`/lists/${obj.list.id}/`, social);
  }

  if (account_logo) {
    return patch(`/accounts/${User?.username}/`, { logo: account_logo });
  }

  if (template_logo) {
    return patch(`/templates/${obj.template_id}/`, { logo: template_logo });
  }

  return Promise.resolve();
};

/**
 * Render an editable subject field for newsletters.
 *
 * Keeps track of last persisted subject and saves changes to the subject when
 * focus is removed from the input field.
 *
 * @param {Number} props.id The ID of the newsletter the subject is for.
 * @param {String} props.subject The current subject of the newsletter.
 */
const SubjectField = ({ id, subject = '' }) => {
  const i18n = useContext(TranslationsContext);
  const persistedValue = useRef(subject);
  const [subjectValue, inputProps] = useInput(subject ?? '');
  const [shouldSave, setShouldSave] = useState(false);

  useEffect(() => {
    if (shouldSave && persistedValue.current !== subjectValue) {
      updateState('processing', true);
      patch(`/newsletters/${id}/`, { subject: subjectValue })
        .then(() => {
          persistedValue.current = subjectValue;
          clearMessages();
        })
        .catch(() => {
          addAlertMessage(
            i18n.gettext('An unknown error occurred while saving changes.')
          );
        })
        .finally(() => {
          updateState('processing', false);
        });
    }

    setShouldSave(false);
  }, [id, i18n, shouldSave, subjectValue]);

  return (
    <div className="input-group">
      <label htmlFor="newsletter-subject" className="input-group-label">
        {i18n.gettext('Subject')}
      </label>
      <input
        type="text"
        id="newsletter-subject"
        className="input-group-field"
        placeholder={i18n.gettext('Not set')}
        onBlur={() => setShouldSave(true)}
        {...inputProps}
      />
    </div>
  );
};

SubjectField.propTypes = {
  id: PropTypes.number.isRequired,
  subject: PropTypes.string,
};

/**
 * Email Content editor step controller.
 *
 * Initializes a new email content editor for the textarea with the content,
 * configuring it with dynamic content, product browser and template configuration.
 */
const EmailContentStep = {
  init(container, state) {
    const { id, subject, template, list } = state;
    const { fonts, palettes } = template?.data ?? template?.settings ?? {};
    const controlsContainer = document.querySelector('.extra-header-controls');

    createEditorConfig({
      ckeditor: {
        removePlugins: removedPlugins + ',embed',
      },
      dynamicContent: {
        follow: list,
        address: {
          name: User?.name,
          address: User?.address,
          zipcode: User?.zipcode,
          city: User?.city,
          email: User?.email,
        },
        phone: {
          phoneNumber: User?.phone,
        },
      },
      dataItems: {
        username: User?.username,
      },
      dialogs: {
        linkDialog: {
          disableTarget: true,
        },
      },
      template: { fonts, palettes, slug: template?.slug },
      personalization: getState('personalization'),
    }).then((config) => {
      const root = createRoot(container);
      root.render(
        <Translations>
          <ContentEditorContainer
            config={config}
            controlsContainer={controlsContainer}
            items={[
              {
                resource: 'newsletters',
                stateKey: 'campaign',
                field: 'html',
                updateField: 'saved',
                styleContent: `
                  .mm-editor-content {
                    align-self: flex-start;
                  }
                `,
              },
            ]}
            onSettingsChanged={onSettingsChanged}
          >
            <SubjectField id={id} subject={subject} />
          </ContentEditorContainer>
        </Translations>
      );
    });

    /**
     * Disable the click handler for navigation links in the steps controller for
     * newsletters on the content step. We now handle that directly in the
     * ContentEditor component to make sure changes are saved.
     */
    return {
      end: () => {},
    };
  },
};

export default EmailContentStep;
