/**
 * Newsletter creation process.
 */
import { createRoot } from 'react-dom/client';

import AutoSaveFieldController from './controllers/auto-save-field';
import { normalize } from '../utils/api';
import getMessages, { defaultLang } from '../i18n';
import ProgressBar from '../components/progress-bar';
import FormProcessingController from './controllers/form-processing';
import ShareService from '../share/service';
import createStickyNavigation from '../components/sticky-navigation';
import createFieldValidator from './validation';
import getSocket from '../websockets';
import { initClickRateChart } from 'visualizations/click-rate-chart';

const jQuery = window.jQuery;

/**
 * Show progress bar for newsletters that are sending.
 */
const setupProgressBars = (bars, socket) => {
  const renderProgress = (bar, value) => {
    const progress = Math.round(value * 100);
    if (progress <= 100) {
      const root = createRoot(bar);
      root.render(<ProgressBar progress={progress} />);
    }
  };

  bars.forEach((bar) => {
    renderProgress(bar, 0);

    // TODO: Remove this when we can emit websocket events in MojoMaster!
    setInterval(() => {
      socket.emit('sending_progress_request', {
        mid: parseInt(bar.dataset.id, 10),
      });
    }, 2000);
  });

  socket.on('sending_progress_response', ({ mid, value, is_sent }) => {
    const bar = bars.find((element) => {
      return parseInt(element.dataset.id, 10) === mid;
    });
    if (!bar) return;

    renderProgress(bar, value);
    if (is_sent) {
      setTimeout(() => window.location.reload(), 500);
    }
  });
};

/**
 * Initialize newsletters interface elements.
 */
const initNewsletters = () => {
  const autosaveFields = Array.from(document.querySelectorAll('input[data-autosave]'));
  const forms = Array.from(document.querySelectorAll('form'));
  const clipboardField = document.getElementById('clipboard-input');
  const shareButtons = Array.from(document.querySelectorAll('.share.button'));
  const validatableFields = Array.from(document.querySelectorAll('[data-validate]'));
  const previewTabs = document.querySelector('.preview-container .tabs');
  const progressBars = Array.from(
    document.querySelectorAll('[data-newsletter-progress]')
  );
  const smsClickChart = document.getElementById('sms-click-chart');

  if (progressBars.length > 0) {
    getSocket().then((socket) => {
      setupProgressBars(progressBars, socket);
    });
  }

  if (window.MailMojo?.State?.templates) {
    window.MailMojo.State.templates = normalize(window.MailMojo.State.templates);
  }

  // Initiate form processing indication on all forms on the page.
  forms.forEach((form) => {
    FormProcessingController.create({ form });
  });

  autosaveFields.forEach((field) => {
    AutoSaveFieldController.create(field);
  });

  shareButtons.forEach((button) => {
    ShareService.init(button.getAttribute('data-service'), button, {
      url: clipboardField.value,
    });
  });

  validatableFields.forEach((input) => createFieldValidator(input));

  if (clipboardField) {
    clipboardField.addEventListener('click', () => clipboardField.select());
  }

  /*
   * Add sticky navigation and height adjustments handlers for all other steps
   * than the content step. The content step has separate handling of sticky
   * navigation and content height adjustments.
   */
  if (!document.querySelector('.content-editor-container')) {
    createStickyNavigation();
  }

  /*
   * For embedded apps we trigger a resize event when content inside the preview
   * tabs changes.
   */
  if (previewTabs) {
    jQuery(previewTabs).on('change.zf.tabs', () => {
      window.dispatchEvent(new Event('resize'));
    });
  }

  /*
   * Initialize SMS campaign stats chart.
   */
  if (smsClickChart) {
    const { clickHistory, numRecipients } = window.MailMojo.State;
    initClickRateChart(
      smsClickChart,
      clickHistory,
      numRecipients,
      defaultLang,
      getMessages
    );
  }
};

export default initNewsletters;
