export interface JobResponseData<ResultType> {
  status: string;
  result?: ResultType;
}

const userHasProduct = (productId: string): boolean => {
  if (!window.MailMojo.User) {
    return false;
  }
  return window.MailMojo.User.products.indexOf(productId) !== -1;
};

const jobIsDone = (status: string): boolean =>
  ['finished', 'stopped', 'canceled', 'failed'].indexOf(status) !== -1;

/**
 * Helper function to fetch the status of an RQ job.
 *
 * @param jobId The RQ job ID to fetch status of.
 * @returns Status of the job.
 */
const fetchJobStatus = async <ResultType>(
  jobId: string
): Promise<JobResponseData<ResultType>> => {
  const response = await fetch(`/jobs/${jobId}/status/`);
  return (await response.json()) as JobResponseData<ResultType>;
};

/**
 * Helper function to await completion of an RQ job, and retrieve the result if the
 * job was successful. We also return the status of the job.
 *
 * @param jobId The RQ job ID to resolve result of.
 * @param checkInterval Time in seconds between job status checks for results.
 * @returns Status of job when done, and result if job was successful.
 */
const getJobResult = async <ResultType>(
  jobId: string,
  checkInterval = 5
): Promise<JobResponseData<ResultType>> => {
  const sleep = (secs: number): Promise<void> =>
    new Promise((cont) => setTimeout(cont, secs * 1000));
  let data = await fetchJobStatus<ResultType>(jobId);
  while (!jobIsDone(data.status)) {
    await sleep(checkInterval);
    data = await fetchJobStatus<ResultType>(jobId);
  }

  return {
    status: data.status,
    result: data.result,
  };
};

export { getJobResult, fetchJobStatus, userHasProduct };
