import EmailContentController from './controllers/email-content-step';
import SMSContentController from './controllers/sms-content-step';
import RecipientsController from './controllers/recipients-step';
import SendController from './controllers/send-step';
import TemplatesController from './controllers/templates-step';
import { get as getState } from '../state';
const steps = {
  recipients(el) {
    const recipientsForm = el.querySelector('form.recipients');
    return RecipientsController.init(recipientsForm);
  },

  template(el) {
    const templatesForm = el.querySelector('form.templates-list');
    return TemplatesController.init(templatesForm);
  },

  content(el) {
    const content = el.querySelector('.campaigns .content-editor-container');
    const campaign = getState('campaign');

    if (campaign?.medium === 'email') {
      return EmailContentController.init(content, { ...campaign });
    }

    if (campaign?.medium === 'sms') {
      return SMSContentController.init(content, {
        personalization: getState('personalization'),
        ...campaign,
      });
    }
  },

  send(el) {
    const sendForm = el.querySelector('form.send');
    return SendController.init(sendForm);
  },
};

/**
 * Return step controller for given page in campaign flow.
 */
const getStep = () => {
  const main = document.querySelector('main');
  return steps[main?.dataset.wizardStep]?.(main) ?? null;
};

export { getStep };
