/* eslint-disable react/no-danger */
import PropTypes from 'prop-types';

import Icon from 'common/lib/components/icon';

const MessageList = ({ messages, getIconName, onRemove = null }) =>
  messages.length > 0 ? (
    <ul>
      {messages.map(([category, info], index) => (
        <li
          key={info.message}
          className={[
            'display-block',
            index + 1 !== messages.length ? 'padding-bottom' : '',
          ].join(' ')}
        >
          <article className={`callout padding-x padding-y ${category}`}>
            <header
              className={[
                'flex-container',
                'align-middle',
                category === 'success' ? 'basic' : '',
                info.description || info.button_text ? 'padding-bottom' : '',
              ].join(' ')}
            >
              <Icon name={getIconName(category)} />
              <h4
                className="padding-left-small padding-right"
                dangerouslySetInnerHTML={{ __html: info.message }}
              />
              {onRemove && (
                <button
                  type="button"
                  className="close-button"
                  onClick={() => onRemove(index)}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              )}
            </header>
            {(info.description || info.button_text) && (
              <div className="content flex-container flex-dir-column">
                {info.description && (
                  <p
                    className="padding-bottom"
                    dangerouslySetInnerHTML={{ __html: info.description }}
                  />
                )}
                {info.button_text && info.button_link && (
                  <a
                    href={info.button_link}
                    className="button secondary align-self-bottom"
                  >
                    {info.button_text}
                  </a>
                )}
              </div>
            )}
          </article>
        </li>
      ))}
    </ul>
  ) : null;

MessageList.propTypes = {
  messages: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.any)).isRequired,
  getIconName: PropTypes.func.isRequired,
  onRemove: PropTypes.func,
};

export default MessageList;
