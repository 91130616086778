/* eslint-disable import/extensions */
import { createRoot } from 'react-dom/client';

import ScrollListener from 'common/lib/scroll-listener';

import { createTracking } from './analytics';
import createConfirmDialog from '../components/confirm-dialog';
import createCountdownTimer from '../components/countdown';
import { createContactStatus } from '../components/contacts-status';
import { createCreditsStatus } from '../components/credits-status';
import { renderOtpInput } from '../../js/accounts/recovery-phone-number';
import createHUD from '../components/hud';
import createIntercomMessage from '../components/intercom-message';
import { copyFromInput, copyValue } from '../components/clipboard';
import { setupBrregField } from '../components/brreg';
import getMessages from '../i18n';
import LanguageSelector from '../components/language-selector';
import setupFoundation from './foundation';
import setupFormSubmitLoader from '../components/form-submit-loader';
import setupMessages, { addAlertMessage } from './messages';
import setupProcessingIndicator from '../components/processing-indicator';
import { setupImportJobFeedback } from '../contacts/helpers';
import { getLocalStorageItem, setLocalStorageItem } from '../utils';

const jQuery = window.jQuery;

/**
 * Initialize common interface elements.
 *
 * Sets up loading screen when on that page, then sets up buttons that triggers
 * Intercom chat and buttons that display a confirmation dialog before
 * proceeding with the action.
 *
 * Finally creates a height emitter for when we're embedded.
 */
const init = () => {
  const isEmbedded = window.top !== window;
  const languageSelector = document.querySelector('.language-selector');
  const confirmDialogs = Array.from(document.querySelectorAll('[data-confirm-text]'));
  const intercomButtons = Array.from(document.querySelectorAll('[data-intercom-text]'));
  const analyticsButtons = Array.from(
    document.querySelectorAll('[data-analytics-event]')
  );
  const contactsStatusContainer = document.getElementById('contacts-status-container');
  const creditsStatusContainer = document.getElementById('credits-status-container');
  const otpInputField = document.querySelector('[data-input-type=otp]');
  const copyContainers = Array.from(document.querySelectorAll('.clipboard-copy'));
  const submitButtons = Array.from(document.querySelectorAll('[type="submit"]'));
  const brregInputField = document.querySelector('[data-brreg]');

  const mirrorInputsToElements = Array.from(
    document.querySelectorAll('[data-mirror-input-to]')
  );
  const countdownTimers = Array.from(document.querySelectorAll('[data-countdown]'));
  const breakoutLinks = Array.from(document.querySelectorAll('a[target="_top"]'));
  const previewTabs = jQuery('#preview-tabs');

  submitButtons.forEach((button) => {
    if (button.form.dataset.abide !== undefined) {
      setupFormSubmitLoader(button);
    }
  });

  if (languageSelector) {
    getMessages().then((i18n) => {
      const languages = [
        { flag: 'no', locale: 'nb_NO', name: i18n.gettext('Norwegian') },
        { flag: 'gb', locale: 'en_US', name: i18n.gettext('English') },
      ];
      const root = createRoot(languageSelector);

      root.render(
        <LanguageSelector
          languages={languages}
          currentLocale={languageSelector.dataset.locale}
        />
      );
    });
  }

  copyContainers.forEach((container) => {
    const input = container.querySelector('input');
    const button = container.querySelector('button');

    button.addEventListener('click', () => {
      if (input) {
        copyFromInput(button, input);
      } else if (button.dataset.copyValue !== undefined) {
        copyValue(button, button.dataset.copyValue);
      }
    });
  });

  mirrorInputsToElements.forEach((element) => {
    const toElement = document.querySelector(element.dataset.mirrorInputTo);
    if (toElement) {
      const initialContent = toElement.textContent;
      const setContent = () => {
        toElement.textContent = `${initialContent}${element.value}`;
      };
      element.addEventListener('input', setContent);
      setContent();
    }
  });

  if (brregInputField) {
    setupBrregField(brregInputField);
  }

  setupProcessingIndicator();

  setupFoundation();
  setupMessages();

  confirmDialogs.forEach((element) => {
    createConfirmDialog(element);
  });

  countdownTimers.forEach((element) => {
    createCountdownTimer(element, Date.parse(element.dataset.expires));
  });

  intercomButtons.forEach((element) => {
    createIntercomMessage(element);
  });

  analyticsButtons.forEach((element) => {
    createTracking(element);
  });

  if (contactsStatusContainer) {
    createContactStatus(contactsStatusContainer);
  }

  if (creditsStatusContainer) {
    createCreditsStatus(creditsStatusContainer);
  }

  if (otpInputField) {
    renderOtpInput(otpInputField);
  }

  setupImportJobFeedback();

  if (isEmbedded) {
    breakoutLinks.forEach((link) => {
      let href = link.getAttribute('href');
      const sep = href.indexOf('?') !== -1 ? '&' : '?';

      if (href.indexOf('://') === -1 || href.indexOf('mailmojo') !== -1) {
        link.setAttribute('href', `${href}${sep}is_embedded=true`);
      }
    });
  }

  ScrollListener.init({ threshold: 400 });
  // Expose it for external usage (eg. in inner frames)
  global.ScrollListener = ScrollListener;

  createHUD();

  /**
   * Update the disabled state of an input fields in a container based on the
   * `isEnabled` flag.
   *
   * @param {Element} container
   * @param {boolean} isEnabled
   */
  const toggleInputFields = (container, isEnabled) => {
    container.querySelectorAll('input').forEach((input) => {
      input.disabled = !isEnabled;
    });
  };

  document.querySelectorAll('[data-toggle-target]').forEach((toggleSwitch) => {
    const container = document.getElementById(toggleSwitch.dataset.toggleTarget);

    if (toggleSwitch && container) {
      toggleInputFields(container, toggleSwitch.checked);

      toggleSwitch.addEventListener('change', () => {
        toggleInputFields(container, toggleSwitch.checked);
      });
    }
  });

  /**
   * Handle errors by reverting input changes and displaying an alert message.
   *
   * @param {Element} inputElement
   * @param {string} errorMessage
   */
  const handleInputError = async (inputElement, errorMessage) => {
    inputElement.checked = !inputElement.checked;
    addAlertMessage(errorMessage);
  };

  document.querySelectorAll('[data-auto-submit]').forEach((inputElement) => {
    inputElement.addEventListener('change', () => {
      const form = inputElement.form;
      const formData = new FormData(form);
      inputElement.disabled = true;

      fetch(form.action, {
        headers: {
          Accept: 'application/json',
        },
        method: 'POST',
        body: formData,
      })
        .then(async (response) => {
          if (!response.ok || response.redirected) {
            const i18n = await getMessages();
            handleInputError(
              inputElement,
              i18n.sprintf(i18n.gettext('%(object)s could not be saved'), {
                object: i18n.gettext('Settings'),
              })
            );
          }
        })
        .catch(async () => {
          const i18n = await getMessages();
          handleInputError(
            inputElement,
            i18n.gettext('An unknown error occurred while saving changes.')
          );
        })
        .finally(() => {
          inputElement.disabled = false;
        });
    });
  });

  document.querySelectorAll('[data-match-expect]').forEach((el) => {
    /**
     * Enables or disables delete buttons based on text confirmation.
     *
     * For each element with a `data-match-expect` attribute, this function:
     * - Retrieves the expected value from the `data-match-expect` attribute.
     * - Enables the delete button only if the input value matches the expected value.
     *
     * This is typically used to confirm an action by requiring the user to type
     * a lists name (e.g., item name) to enable the delete button.
     */
    const value = el.dataset.matchExpect;
    const inputElement = el.querySelector('input');
    const submitButton = el.querySelector('button[type="submit"]');

    inputElement.addEventListener('input', () => {
      submitButton.disabled = inputElement.value !== value;
    });
  });

  /**
   * Persist the active tab state across form and page preview step.
   *
   * Saves the selected tab (e.g., mobile or tab view) in localStorage,
   * ensuring the same tab remains active when switching views. Restores the
   * tab state on page load, providing a consistent preview experience.
   */
  if (previewTabs) {
    const state = window.MailMojo.State;
    const activeTabKey = state.popupform
      ? `popupform-${state.popupform.id}-activeTab`
      : state.page
        ? `page-${state.page.id}-activeTab`
        : '';

    if (activeTabKey) {
      // Restore the previously saved active tab on page load
      const savedTabId = getLocalStorageItem(activeTabKey);
      if (savedTabId) {
        jQuery(`#preview-tabs a[href="${savedTabId}"]`).trigger('click');
      }

      previewTabs.on('change.zf.tabs', (event, element) => {
        const tabId = jQuery(element).find('a').attr('href');
        setLocalStorageItem(activeTabKey, tabId);
      });
    }
  }
};

export default init;
