import PropTypes from 'prop-types';
import { Component } from 'react';
import ChecklistItem from '../../components/checklist-item';
import CopyToClipboard from '../../components/copy-to-clipboard';
import { StringField } from '../../components/fields';
import { TranslationsContext } from '../../components/translate';
import { get as getState } from '../../state';
import { patch } from '../../utils/api';

/**
 * <TriggersChecklistItem />
 *
 * A checklist item component for configuring a trigger.
 */
const TriggersChecklistItem = ({
  title,
  description,
  label,
  name,
  value = null,
  defaultValue = null,
  isOpen,
  onOpen,
  onClose,
  onSwitch,
  error = null,
  ...checklistItemProps
}) => {
  return (
    <ChecklistItem
      title={title}
      description={description}
      isValid={!!value || null}
      switchEnabled={!!value || isOpen}
      toggleSwitch={(isOn) => {
        if (isOn) {
          onOpen();
        } else {
          onClose();
          if (value !== null) onSwitch({ key: name, value: isOn });
        }
      }}
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      onSwitch={onSwitch}
      {...checklistItemProps}
    >
      <StringField
        label={label}
        inputProps={{
          name: name,
          defaultValue: `${value ?? defaultValue ?? ''}`,
        }}
        isRequired={false}
        isErrorVisible={!!error}
        errorMessage={error}
      />
    </ChecklistItem>
  );
};

TriggersChecklistItem.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.number,
  defaultValue: PropTypes.number,
  isOpen: PropTypes.bool.isRequired,
  onOpen: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onSwitch: PropTypes.func.isRequired,
  error: PropTypes.string,
};

/**
 * <TriggersButtonClickChecklistItem />
 *
 * A checklist item component for configuring a button click trigger.
 */
const TriggersButtonClickChecklistItem = ({
  title,
  description,
  name,
  value = null,
  publicUrl,
  isOpen,
  onOpen,
  onClose,
  onSwitch,
  error = null,
  canEdit = false,
  ...checklistItemProps
}) => {
  return (
    <ChecklistItem
      canEdit={canEdit}
      title={title}
      description={description}
      isValid={!!value || null}
      switchEnabled={!!value || isOpen}
      toggleSwitch={(isOn) => {
        isOn ? onOpen() : onClose();
        onSwitch({
          key: name,
          value: isOn,
        });
      }}
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      onSwitch={onSwitch}
      {...checklistItemProps}
    >
      {value && <CopyToClipboard text={`<a href="${publicUrl}">Subscribe</a>`} />}
    </ChecklistItem>
  );
};

TriggersButtonClickChecklistItem.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.number,
  publicUrl: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  onOpen: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onSwitch: PropTypes.func.isRequired,
  error: PropTypes.string,
  canEdit: PropTypes.bool,
};

/**
 * <TriggersChecklist />
 *
 * A checklist component for configuring popup form triggers.
 */
class TriggersChecklist extends Component {
  static contextType = TranslationsContext;
  state = {
    triggers: {
      page_time: null,
      page_scroll: null,
      site_time: null,
      button_click: null,
    },
    isSaving: false,
    current: null,
    error: null,
    previousError: null,
  };

  componentDidMount() {
    this.objToState(getState('popupform'));
  }

  handleOpen = (current) => {
    const { error } = this.state;
    this.setState({ current, previousError: error });
  };

  handleClose = (userCanceled = false) => {
    const { previousError } = this.state;

    this.setState({
      current: null,
      isSaving: false,
      previousError: null,
      ...(userCanceled ? { error: previousError } : {}),
    });
  };

  /**
   * Handle the switching of checklist items.
   *
   * This method is responsible for handling the switching of checklist items.
   * It takes a 'data' object with 'key' representing the checklist item and 'value'
   * as a boolean indicating whether the item is switched on or off. If the item is
   * switched on, it calls 'handleOpen' to open the checklist item, and then it calls '
   * saveTriggers' to save the item with a value of 1. If the item is switched off, it
   * calls 'handleClose' to close the checklist item and then 'saveTriggers' with a
   * value of null.
   *
   * @param {object} data - An object containing 'key' (checklist item) and 'value'
   * (a boolean).
   */
  handleSwitch = (data) => {
    const { key, value } = data;
    // If value is set to null the trigger it self will be removed from the triggers
    // on the form
    this.saveTriggers(key, value ? 1 : null);
  };

  /**
   * Save trigger data to the API.
   *
   * Depending on the current checklist item that is open, it prepares the data
   * structure for submission. It also handles API response and potential errors,
   * updating the component's state accordingly.
   *
   * @param {string} current - The current checklist item being edited.
   * @param {string|null} value - The value associated with the current checklist item.
   */
  saveTriggers = (current, value) => {
    const { id, error, triggers } = this.state;
    let data = Object.entries(triggers)
      .filter(([triggerType, value]) => triggerType !== current && value !== null)
      .map(([triggerType, value]) => {
        return {
          rules: [
            {
              trigger_type: triggerType,
              value: parseInt(value, 10),
            },
          ],
        };
      });

    if (value && value !== null) {
      data.push({
        rules: [{ trigger_type: current, value: parseInt(value, 10) }],
      });
    }

    this.setState({ isSaving: true });
    patch(`/forms/${id}/`, { trigger: data }).then(
      (obj) => {
        this.objToState(obj);
        this.handleClose();
      },
      (result) => {
        let currentError = null;
        if (result?.errors) {
          let errorKey = Object.keys(result.errors.trigger).shift();
          if (result.errors.trigger[errorKey].rules) {
            currentError =
              result.errors.trigger[errorKey].rules[0]._schema[0]?.[current];
          } else {
            currentError = result.errors.trigger['trigger'];
          }
        }
        this.setState({
          isSaving: false,
          error: { ...error, [current]: currentError?.[0] },
        });
      }
    );
  };

  /**
   * Handle the submission of form data.
   *
   * This method is responsible for initiating the submission of form data to the API.
   * It calls the 'saveTriggers' method, passing relevant data.
   *
   * @param {Event} event - The event object triggered by the form submission.
   */
  handleSubmit = (event) => {
    const { current, error } = this.state;
    const { value } = current ? event.target[current] : { value: null };
    // Nothing to save or errors to present so we bail out for backend to handle further
    // validation and proceed.
    if (!current && !error) {
      return;
    }
    event.preventDefault();
    this.saveTriggers(current, value);
  };

  /**
   * Extracts triggers data from object config.
   *
   * This function extracts existing trigger data from the provided object's
   * configuration and prepares it for use in the state data. Currently, the
   * implementation supports only OR rules and, as a result, extracts the
   * value from the first rule within the rules array.
   *
   * @param {Object} obj
   */
  getAllTriggerValues = (obj) => {
    const triggers = obj?.config?.trigger ?? [];
    return triggers.reduce((result, trigger) => {
      const rule = trigger.rules[0];
      result[rule.trigger_type] = rule.value;
      return result;
    }, {});
  };

  /**
   * Extracts data from a `form` object to state data.
   *
   * triggers are defined in `form.config`.
   *
   * @param {Object} obj
   */
  objToState(obj) {
    const { current, error } = this.state;
    const previousErrors = error ?? {};
    const newErrors = obj.errors?.config ?? {};
    const mergedErrors = Object.entries({
      ...previousErrors,
      ...newErrors,
    }).reduce((filteredErrors, [key, value]) => {
      if (key !== current) {
        Object.assign(filteredErrors, { [key]: value });
      }

      return filteredErrors;
    }, {});
    this.setState({
      id: obj.id,
      error: Object.keys(mergedErrors).length ? mergedErrors : null,
      triggers: this.getAllTriggerValues(obj),
      publicUrl: obj.public_url,
    });
  }

  render() {
    const { current, triggers, publicUrl, error, isSaving } = this.state;
    const i18n = this.context;
    const checklistItemProps = (key) => ({
      isOpen: current === key,
      isSaving,
      onOpen: () => this.handleOpen(key),
      onClose: () => this.handleClose(true),
      onSwitch: (data) => this.handleSwitch(data),
      name: key,
      value: triggers[key],
      error: error && error[key],
    });

    return (
      <form onSubmit={this.handleSubmit} method="post" noValidate>
        <input type="hidden" name="next" value="" />

        <TriggersChecklistItem
          title={i18n.gettext('Time on web page')}
          defaultValue={8}
          description={
            triggers['page_time'] !== null && triggers['page_time'] !== undefined
              ? i18n.sprintf(
                  i18n.ngettext(
                    'This popup will be displayed after <em>%(value)d second</em>.',
                    'This popup will be displayed after <em>%(value)d seconds</em>.',
                    triggers['page_time']
                  ),
                  { value: triggers['page_time'] }
                )
              : i18n.gettext(
                  'Configure the duration in seconds a visitor should spend on a ' +
                    'page before the popup appears.'
                )
          }
          label={i18n.gettext('Number of seconds')}
          {...checklistItemProps('page_time')}
        />

        <TriggersChecklistItem
          title={i18n.gettext('Scroll percentage')}
          description={
            triggers['page_scroll'] !== null && triggers['page_scroll'] !== undefined
              ? i18n.sprintf(
                  i18n.gettext(
                    'This popup will be displayed after scrolling <em>%(value)d' +
                      ' percent</em> down on the page.'
                  ),
                  { value: triggers['page_scroll'] }
                )
              : i18n.gettext(
                  'Configure the percentage a visitor should scroll down on the' +
                    ' page before the popup appears.'
                )
          }
          label={i18n.gettext('Percentage')}
          {...checklistItemProps('page_scroll')}
        />

        <TriggersChecklistItem
          title={i18n.gettext('Total time on website')}
          description={
            triggers['site_time'] !== null && triggers['site_time'] !== undefined
              ? i18n.sprintf(
                  i18n.ngettext(
                    'This popup will be displayed after spending <em>%(value)d second' +
                      '</em> on the website.',
                    'This popup will be displayed after spending <em>%(value)d ' +
                      'seconds</em> on the website.',
                    triggers['site_time']
                  ),
                  { value: triggers['site_time'] }
                )
              : i18n.gettext(
                  'Configure the total duration in seconds a visitor should spend on ' +
                    'the website before the popup appears.'
                )
          }
          label={i18n.gettext('Number of seconds')}
          {...checklistItemProps('site_time')}
        />

        <TriggersButtonClickChecklistItem
          title={i18n.gettext('Click on a link')}
          description={
            triggers['button_click'] !== null && triggers['button_click'] !== undefined
              ? i18n.gettext(
                  'Place this link anywhere on your website to display the popup ' +
                    'when clicked.'
                )
              : i18n.gettext(
                  'Get a link to place on your website which displays the popup ' +
                    'when clicked.'
                )
          }
          publicUrl={publicUrl}
          {...checklistItemProps('button_click')}
        />
      </form>
    );
  }
}

export default TriggersChecklist;
