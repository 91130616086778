import { isValidElement, useContext } from 'react';

import { Status } from '../common/constants';

import ItemIcon from './checklist-item-icon';
import Switch from './switch';
import { TranslationsContext } from './translate';

interface ChecklistItemProps {
  title: string;
  description?: React.ReactNode | string;
  editUrl?: string | null;
  isValid?: boolean | null;
  isOpen?: boolean;
  isSaving?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
  status?: (typeof Status)[keyof typeof Status] | null;
  toggleSwitch?: (checked: boolean) => void;
  switchEnabled?: boolean;
  children?: React.ReactNode;
  canEdit?: boolean;
  viewportSize?: string;
}

const ChecklistItem: React.FC<ChecklistItemProps> = ({
  title,
  description = '',
  editUrl = null,
  isValid = null,
  isOpen = false,
  isSaving,
  onOpen,
  onClose,
  status = null,
  toggleSwitch = null,
  switchEnabled = null,
  children = null,
  canEdit = true,
}) => {
  const i18n = useContext(TranslationsContext);
  return (
    <section className="grid-x align-middle padding-top">
      <div className="cell padding-right shrink">
        <ItemIcon isValid={isValid} status={status} />
      </div>
      <div className="cell auto">
        <h2 className="h3">{title}</h2>
      </div>
      <div className="cell shrink flex-container align-middle">
        {toggleSwitch && (
          <div className={`switch-container ${canEdit ? 'padding-right' : ''}`}>
            <Switch
              isActive={switchEnabled}
              onChange={({ target }: React.ChangeEvent<HTMLInputElement>) => {
                toggleSwitch(target.checked);
              }}
              toggleLabel={i18n.gettext('Toggle')}
              onLabel={i18n.gettext('On')}
              offLabel={i18n.gettext('Off')}
              size="small"
            />
          </div>
        )}

        {editUrl && (
          <a href={editUrl} className="button secondary small">
            {i18n.gettext('Edit')}
          </a>
        )}
        {canEdit && onOpen && !editUrl && (
          <button
            type="button"
            className="button secondary small"
            onClick={() => onOpen()}
            disabled={isOpen}
          >
            {i18n.gettext('Edit')}
          </button>
        )}
      </div>
      <div className="cell small-12">
        <div style={{ paddingLeft: '3rem' }}>
          {!isOpen ? (
            <small>
              {isValidElement(description) ? (
                description
              ) : (
                <span dangerouslySetInnerHTML={{ __html: description as string }} />
              )}
            </small>
          ) : (
            <>
              {children}
              {canEdit && (
                <>
                  <div className="padding-top button-group small">
                    <button
                      type="submit"
                      className="button primary"
                      disabled={isSaving}
                    >
                      {isSaving ? i18n.gettext('Please wait…') : i18n.gettext('Save')}
                    </button>
                    <button
                      type="button"
                      className="button secondary"
                      onClick={onClose}
                    >
                      {i18n.gettext('Cancel')}
                    </button>
                  </div>
                </>
              )}
            </>
          )}
          {/* Make sure it's possible to show the children, when using the switch, even
          though the component is closed but the switch is toggled on. */}
          {switchEnabled && !isOpen && !canEdit && <>{children}</>}
        </div>
      </div>
    </section>
  );
};

export default ChecklistItem;
