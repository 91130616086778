/* eslint-disable react/no-danger */
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import createCanceablePromise from '../cancelable-promise';

const createIconLoader = (name) => {
  const [promise, cancel] = createCanceablePromise(
    // XXX: Don't uhderstand why this doesn't work for stats icons...?
    import(/* webpackMode: "eager" */ `../../icons/${name}.svg`)
  );
  return [promise.then(({ default: svg }) => svg), cancel];
};

/**
 * Component for rendering an SVG icon element.
 */
const Icon = ({ name, classNames = '' }) => {
  const [svg, setSVG] = useState();

  useEffect(() => {
    const [loader, cancel] = createIconLoader(name);
    loader.then(setSVG).catch(() => {});
    return cancel;
  }, [name]);

  if (svg) {
    return (
      <div
        className={`${classNames} icon`}
        dangerouslySetInnerHTML={{ __html: svg }}
      ></div>
    );
  }

  return null;
};

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  classNames: PropTypes.string,
};

export const getIcon = (name) => createIconLoader(name)[0];

export default Icon;
