import { max, mean } from 'd3-array';
import { axisBottom, axisLeft } from 'd3-axis';
import { scaleBand, scaleLinear } from 'd3-scale';
import { select, Selection } from 'd3-selection';
import { format } from 'common/lib/dates';

import './click-rate-chart.scss';

interface TimePointData {
  time: Date;
  value: number;
  totalValue: number;
}

interface TimeSeriesData {
  hour: TimePointData[];
  day: TimePointData[];
  week: TimePointData[];
}

interface ChartOptions {
  margin: { top: number; right: number; bottom: number; left: number };
  height: number;
}

interface i18n {
  gettext: (key: string) => string;
  sprintf: (key: string, params: Record<string, string | number>) => string;
}

class ClickRateChart {
  private container: HTMLElement;
  private options: ChartOptions;
  private tooltip!: Selection<HTMLDivElement, unknown, HTMLElement, unknown>;
  private lang: string;
  private i18n: i18n;

  /**
   * Creates an instance of the ClickRateChart class.
   *
   * @param container The container element for rendering the chart.
   * @param options Optional configuration for chart dimensions and margins.
   */
  constructor(
    container: HTMLElement,
    options: Partial<ChartOptions> = {},
    lang: string,
    i18n: i18n
  ) {
    this.container = container;
    this.options = {
      margin: { top: 40, right: 150, bottom: 40, left: 150 },
      height: 400,
      ...options,
    };
    this.lang = lang;
    this.i18n = i18n;
    this.createTooltip();
  }

  /**
   * Formats a date using the formatDate function with default parameters.
   *
   * @param date The date object to format.
   * @param formatStr The format string for formatting the date.
   * @returns The formatted date string.
   */

  private formatDate(date: Date, formatStr: string): string {
    return format(date, formatStr, { locale: this.lang });
  }

  /**
   * Creates a tooltip for displaying information on hover.
   */
  private createTooltip(): void {
    select('.chart-tooltip').remove();
    this.tooltip = select('body')
      .append('div')
      .attr('class', 'chart-tooltip bar-chart-tooltip')
      .style('opacity', 0);
  }

  /**
   * Formats the label for a given date based on the grouping level.
   *
   * @param grouping The grouping level (hour, day, or week).
   * @param date The date to format.
   * @returns A formatted string representing the date label.
   */
  private formatTimeLabel(grouping: string, date: Date): string {
    switch (grouping) {
      case 'hour':
        return (
          this.formatDate(date, 'p') +
          '<br/><small>' +
          this.formatDate(date, 'PPP') +
          '</small>'
        );

      case 'day':
        return this.formatDate(date, 'PPP');

      case 'week':
        return this.i18n.sprintf(this.i18n.gettext('week %(week)s'), {
          week: this.formatDate(date, '%V'),
        });

      default:
        return this.formatDate(date, '%Y-%m-%d');
    }
  }

  /**
   * Formats the tick mark for a given date based on the grouping level.
   *
   * @param grouping The grouping level (hour, day, or week).
   * @param date The date to format.
   * @param total The total number of dates in the data array.
   * @returns A formatted string representing the tick mark.
   */
  private formatTimeTick(grouping: string, date: Date, total = 0): string {
    switch (grouping) {
      case 'hour':
        const hour = this.formatDate(date, '%H');
        return Number(hour) % 2 === 1 ? hour : '•';

      case 'day':
        return this.formatDate(date, 'dd. LLL');

      case 'week':
        const weekNumber = this.formatDate(date, '%V');

        return total <= 12
          ? this.i18n.sprintf(this.i18n.gettext('week %(week)s'), {
              week: weekNumber,
            })
          : Number(weekNumber) % 2 === 0
            ? weekNumber
            : '•';

      default:
        return this.formatDate(date, '%Y-%m-%d');
    }
  }

  /**
   * Renders the bar chart using the provided data and grouping level.
   *
   * @param data The data to display in the chart.
   * @param grouping The grouping level (hour, day, or week). Defaults to 'hour'.
   */
  public render(data: TimePointData[], grouping = 'hour'): void {
    const width =
      this.container.offsetWidth - this.options.margin.left - this.options.margin.right;
    const height =
      this.options.height - this.options.margin.top - this.options.margin.bottom;

    select(this.container).html('');

    const svg = select(this.container)
      .append('svg')
      .attr('width', '100%')
      .attr('height', this.options.height)
      .attr('viewBox', `0 0 ${this.container.offsetWidth} ${this.options.height}`)
      .append('g')
      .attr(
        'transform',
        `translate(${this.options.margin.left},${this.options.margin.top})`
      );

    const x = scaleBand<string>()
      .domain(data.map((d) => d.time.toISOString()))
      .range([0, width])
      .padding(0.1);

    const y = scaleLinear()
      .domain([0, Math.max(max(data, (d) => d.value) ?? 0, 1)])
      .range([height, 0])
      .nice();

    svg
      .append('g')
      .attr('class', 'grid')
      .call(
        axisLeft(y)
          .tickSize(-width)
          .tickFormat(() => '')
      );

    svg
      .append('g')
      .attr('class', 'axis')
      .attr('transform', `translate(0,${height})`)
      .call(
        axisBottom(x).tickFormat((d) =>
          this.formatTimeTick(grouping, new Date(d), data.length)
        )
      );

    svg.append('g').attr('class', 'axis').call(axisLeft(y));

    svg
      .selectAll('.bar-clicks')
      .data(data)
      .enter()
      .append('rect')
      .attr('class', 'bar-clicks')
      .attr('x', (d) => x(d.time.toISOString()) ?? 0)
      .attr('width', x.bandwidth())
      .attr('y', (d) => y(d.value))
      .attr('height', (d) => height - y(d.value))
      .on('mouseover', (event, d) => {
        this.showTooltip(event as MouseEvent, d, grouping);
      })
      .on('mouseout', () => {
        this.tooltip.style('opacity', 0);
      });

    const average = mean(data, (d) => d.value) ?? 0;
    svg
      .append('line')
      .attr('x1', 0)
      .attr('x2', width)
      .attr('y1', y(average))
      .attr('y2', y(average))
      .style('stroke', '#666')
      .style('stroke-dasharray', '5,5')
      .style('stroke-width', 1);

    svg
      .append('text')
      .attr('class', 'annotation')
      .attr('x', width + 5)
      .attr('y', y(average))
      .attr('dy', '.3em')
      .text(
        this.i18n.sprintf(this.i18n.gettext('Avg. %(click)s clicks'), {
          click: Math.round(average),
        })
      );
  }

  /**
   * Shows the tooltip with formatted data.
   *
   * @param event The mouse event triggering the tooltip.
   * @param d The data point for the tooltip.
   * @param grouping The grouping level for the tooltip.
   */
  private showTooltip(event: MouseEvent, d: TimePointData, grouping: string): void {
    const barRect = (event.target as HTMLElement)?.getBoundingClientRect();
    const containerRect = this.container.getBoundingClientRect();
    const tooltipRect = this.tooltip.node()?.getBoundingClientRect();

    if (!barRect || !containerRect || !tooltipRect) return;

    let xPosition = barRect.left + barRect.width / 2;
    const yPosition = barRect.bottom + window.scrollY - tooltipRect.height;

    if (xPosition > containerRect.width / 2) {
      xPosition -= tooltipRect.width;
    }

    this.tooltip
      .style('opacity', 1)
      .html(
        `
      <h4 class="padding-y-small line-height-1">
        ${this.formatTimeLabel(grouping, d.time)}
      </h4>
      <ul>
        <li>
          <label for="tooltip-value">${this.i18n.gettext('Clicks')}</label>
          <span id="tooltip-value">${d.value}</span>
        </li>
        <li>
          <label for="tooltip-click-rate">${this.i18n.gettext('Click rate')}</label>
          <span id="tooltip-click-rate">
            ${((d.value / d.totalValue) * 100).toFixed(1)}%
          </span>
        </li>
      </ul>
      `
      )
      .style('left', `${xPosition}px`)
      .style('top', `${yPosition}px`);
  }
}

/**
 * Processes raw click data into a structured time series grouped by hour, day, and
 * week.
 *
 * @param rawData An array of raw data tuples with time and value count.
 * @param totalValue The total value for calculating rates.
 * @returns An object containing the processed time series data.
 */
function processTimeSeriesData(
  rawData: [string, number][],
  totalValue: number
): TimeSeriesData {
  const timeValueMap: Record<string, number> = {};

  rawData.forEach(([timestamp, count]) => {
    timeValueMap[new Date(timestamp).toISOString()] = count;
  });

  const hourlyData: TimePointData[] = [];
  const startTime = new Date(rawData[0][0]);
  const endTime = new Date(rawData[rawData.length - 1][0]);

  while (startTime <= endTime) {
    const currentTimestamp = startTime.toISOString();
    const count = timeValueMap[currentTimestamp] || 0;
    hourlyData.push({
      time: new Date(currentTimestamp),
      value: count,
      totalValue,
    });
    startTime.setHours(startTime.getHours() + 1);
  }

  const aggregateByTimeUnit = (
    data: TimePointData[],
    unit: string
  ): TimePointData[] => {
    const aggregatedData: Record<string, number> = {};

    data.forEach(({ time, value }) => {
      let timeKey: string;
      if (unit === 'day') {
        timeKey = time.toISOString().substring(0, 10);
      } else if (unit === 'week') {
        const date = new Date(time);
        const startOfWeek = new Date(date.setDate(date.getDate() - date.getDay()));
        timeKey = startOfWeek.toISOString().substring(0, 10);
      } else {
        return;
      }

      aggregatedData[timeKey] = (aggregatedData[timeKey] || 0) + value;
    });

    return Object.entries(aggregatedData).map(([timeKey, count]) => ({
      time: new Date(timeKey),
      value: count,
      totalValue,
    }));
  };

  const dailyData = aggregateByTimeUnit(hourlyData, 'day');
  const weeklyData = aggregateByTimeUnit(dailyData, 'week');

  return {
    hour: hourlyData.slice(0, 48),
    day: dailyData.slice(0, 18),
    week: weeklyData.slice(0, 36),
  };
}

/**
 * Sets the initial grouping for the chart based on the time range between the campaign
 * start and the current time.
 *
 * @param values An array of raw click data tuples with time and click count.
 * @param chart The ClickRateChart instance.
 * @param timeSeriesData The processed time series data.
 */
function setInitialGrouping(
  values: [string, number][],
  chart: ClickRateChart,
  timeSeriesData: TimeSeriesData
): void {
  const startDate = new Date(values[0][0]);
  const endDate = new Date();

  const daysDifference =
    (endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24);
  let defaultGrouping: string;

  if (daysDifference < 1) {
    defaultGrouping = 'hour';
  } else if (daysDifference < 14) {
    defaultGrouping = 'day';
  } else {
    defaultGrouping = 'week';
  }

  (document.getElementById('timeGrouping') as HTMLSelectElement).value =
    defaultGrouping;
  handleGroupingChange(chart, timeSeriesData, defaultGrouping);
}

/**
 * Handles changes to the selected grouping level and re-renders the chart.
 *
 * @param chart The ClickRateChart instance.
 * @param timeSeriesData The processed time series data.
 * @param grouping The selected grouping level (hour, day, or week).
 */
function handleGroupingChange(
  chart: ClickRateChart,
  timeSeriesData: TimeSeriesData,
  grouping: string
): void {
  chart.render(timeSeriesData[grouping as keyof TimeSeriesData], grouping);
}

/**
 * Sets up event listeners for handling user interactions such as grouping selection
 * and window resize.
 *
 * @param chart The ClickRateChart instance.
 * @param timeSeriesData The processed time series data.
 */
function setupEventListeners(
  chart: ClickRateChart,
  timeSeriesData: TimeSeriesData
): void {
  const groupingElement = document.getElementById('timeGrouping') as HTMLSelectElement;

  groupingElement.addEventListener('change', (e) => {
    const selectedGrouping = (e.target as HTMLSelectElement).value;
    handleGroupingChange(chart, timeSeriesData, selectedGrouping);
  });

  let resizeTimeout: NodeJS.Timeout;
  window.addEventListener('resize', () => {
    clearTimeout(resizeTimeout);
    resizeTimeout = setTimeout(() => {
      const currentGrouping = groupingElement.value;
      handleGroupingChange(chart, timeSeriesData, currentGrouping);
    }, 250);
  });
}

/**
 * Initializes the click rate chart with the provided data.
 *
 * @param container The container element for rendering the chart.
 */
async function initClickRateChart(
  container: HTMLElement,
  rawData: [string, number][],
  totalValue: number,
  defaultLang: string,
  i18n: () => Promise<i18n>
) {
  const timeSeriesData = processTimeSeriesData(rawData, totalValue);
  const chart = new ClickRateChart(container, {}, defaultLang, await i18n());

  setInitialGrouping(rawData, chart, timeSeriesData);
  setupEventListeners(chart, timeSeriesData);
}

export { initClickRateChart };
