import { Tooltip as FoundationTooltip } from 'foundation-sites/js/foundation.tooltip';

import { tooltipDefaults } from '../../common/foundation';

const jQuery = window.jQuery;

/**
 * Component for disabling send buttons based on input content.
 *
 * @param {HTMLElement} buttonGroup The container that inclues the buttons.
 * @param {HTMLElement|HTMLElement[]} input The input(s) that we base disabling on.
 * @param {String} tipText Tooltip text when hovering the disabled buttons.
 */
const updateSendButtonState = (buttonGroup, input, tipText) => {
  if (buttonGroup.querySelector('button[disabled]')) {
    return;
  }

  const createTooltip = () =>
    new FoundationTooltip(jQuery(buttonGroup), {
      tipText,
      ...tooltipDefaults,
    });
  const buttons = Array.from(buttonGroup.querySelectorAll('button'));
  let tooltip;
  const inputList = Array.isArray(input) ? input : [input];

  const updateDisabledState = () => {
    const hasContent = inputList.some((input) =>
      input.type === 'checkbox' ? input.checked : input.value.trim() !== ''
    );

    if (hasContent && !tooltip) {
      tooltip = createTooltip();
    } else if (!hasContent && tooltip) {
      tooltip._destroy();
      // Foundation doesn't remove the title so we have to do it
      buttonGroup.removeAttribute('title');
      tooltip = null;
    }

    buttons.forEach((el) => Object.assign(el, { disabled: hasContent }));
  };

  updateDisabledState();

  inputList.forEach((input) => {
    const eventType = input.type === 'checkbox' ? 'change' : 'input';
    input.addEventListener(eventType, updateDisabledState);
  });
};

export default updateSendButtonState;
