import Icon from './icon';

import '../../scss/components/spinner.scss';

const Spinner = () => (
  <div className="spinner">
    <Icon name="freehand/loading-circles" classNames="large" />
  </div>
);

export default Spinner;
