import React, { useContext, useState } from 'react';
import { Translations, TranslationsContext } from '../components/translate';
import { createRoot } from 'react-dom/client';
import { StringField } from '../components/fields';
import { useForm, Controller } from 'react-hook-form';
import { addAlertMessage } from '../common/messages';
import OtpInputField from './otp-input-field';

interface RecoveryPhoneNumberContainerProps {
  phone: string;
}

interface SendPhoneNumberFormProps {
  defaultPhoneValue: string;
  otpSent: boolean;
  setOtpSent: (editing: boolean) => void;
  editing: boolean;
  setEditing: (editing: boolean) => void;
}

interface VerifyOTPFormProps {
  onSendSuccess?: () => void;
  onFormSubmit?: (otp_token: string) => void;
}
interface FormData {
  phone?: string;
  otp_token?: string;
}

interface ApiResponse {
  message: Record<string, string[]>;
}

/**
 * Form component for sending a phone number to receive an OTP code.
 */
const SendPhoneNumberForm: React.FC<SendPhoneNumberFormProps> = ({
  defaultPhoneValue,
  otpSent,
  setOtpSent,
  editing,
  setEditing,
}) => {
  const [isSaving, setIsSaving] = useState(false);
  const i18n = useContext(TranslationsContext);
  const {
    register,
    handleSubmit,
    clearErrors,
    getValues,
    setError,
    formState: { errors },
  } = useForm<FormData>({
    values: {
      phone: defaultPhoneValue,
    },
  });

  const errorMessage = errors?.phone?.message;

  /**
   * Handle and log errors resulting from the API request.
   */
  const handleErrors = (currentError: ApiResponse) => {
    Object.keys(currentError?.message).forEach((key) => {
      setError(key as keyof FormData, {
        type: 'server',
        message: currentError.message[key][0],
      });
    });
  };

  /**
   * Form submission handler.
   */
  const onSubmit = async (data: FormData) => {
    try {
      setIsSaving(true);
      clearErrors();

      const response = await fetch('/auth/account/2fa/recovery/', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        const result = (await response.json()) as ApiResponse;
        handleErrors(result);
      } else {
        setEditing(false);
        setOtpSent(true);
      }
    } catch (e) {
      addAlertMessage(i18n.gettext('An unknown error occurred while saving changes.'));
    } finally {
      setIsSaving(false);
    }
  };

  return editing ? (
    <form
      className="grid-x grid-margin-y-small"
      onSubmit={(event) => void handleSubmit(onSubmit)(event)}
      noValidate
    >
      <div className="cell">
        <StringField
          inputProps={{
            ...register('phone', {
              required: i18n.gettext('Phone number is required'),
            }),
            placeholder: i18n.gettext('Enter your phone number'),
          }}
          isRequired={true}
          isErrorVisible={!!errorMessage}
          errorMessage={errorMessage}
        />
      </div>
      <div
        className={[
          'cell',
          otpSent ? 'flex-container align-middle align-justify' : '',
        ].join(' ')}
      >
        <button type="submit" className="button primary" disabled={isSaving}>
          {i18n.gettext('Send code to confirm')}
        </button>{' '}
        {otpSent ? (
          <button
            type="button"
            className="button link"
            onClick={() => setEditing(false)}
          >
            {i18n.gettext('Already received a code?')}
          </button>
        ) : (
          <button
            type="button"
            className="button secondary"
            data-close="add-recovery-phone"
          >
            {i18n.gettext('Cancel')}
          </button>
        )}
      </div>
    </form>
  ) : (
    <div className="grid-x grid-margin-y-small">
      <p className="cell">
        <span
          dangerouslySetInnerHTML={{
            __html: i18n.sprintf(
              i18n.gettext(
                'A code has been sent to %(phone)s. If the number is incorrect,'
              ),
              {
                phone: `<strong>${getValues('phone')!}</strong>`,
              }
            ),
          }}
        ></span>{' '}
        <button type="button" className="button link" onClick={() => setEditing(true)}>
          {i18n.gettext('change here')}
        </button>
        {'.'}
      </p>
    </div>
  );
};

/**
 * Form component for verifying the OTP code sent to the user's phone.
 */
const VerifyOTPForm: React.FC<VerifyOTPFormProps> = ({ onSendSuccess }) => {
  const [isSaving, setIsSaving] = useState(false);
  const i18n = useContext(TranslationsContext);
  const {
    control,
    handleSubmit,
    clearErrors,
    setError,
    formState: { errors },
  } = useForm<FormData>();

  /**
   * Handle and log errors resulting from the API request.
   */
  const handleErrors = (currentError: ApiResponse) => {
    Object.keys(currentError?.message).forEach((key) => {
      setError(key as keyof FormData, {
        type: 'server',
        message: currentError.message[key][0],
      });
    });
  };

  /**
   * Form submission handler.
   */
  const onSubmit = async (data: FormData) => {
    setIsSaving(true);
    try {
      clearErrors();

      const response = await fetch('/auth/account/2fa/recovery/verify/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        const result = (await response.json()) as ApiResponse;
        handleErrors(result);
      } else {
        onSendSuccess?.();
      }
    } catch (e) {
      addAlertMessage(i18n.gettext('An unknown error occurred while saving changes.'));
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <form
      className="grid-x grid-margin-y-small"
      onSubmit={(event) => void handleSubmit(onSubmit)(event)}
      method="post"
      noValidate
    >
      <div className="cell">
        <Controller
          name="otp_token"
          control={control}
          rules={{ required: i18n.gettext('A code is required') }}
          render={({ field }) => (
            <OtpInputField
              onPasteComplete={() => void handleSubmit(onSubmit)()}
              errorMessage={errors?.otp_token?.message}
              {...field}
            />
          )}
        />
        <div className="cell flex-container">
          <button type="submit" className="button primary" disabled={isSaving}>
            {i18n.gettext('Verify')}
          </button>
        </div>
      </div>
    </form>
  );
};

/**
 * Parent component managing the state of OTP form submission and verification.
 */
const RecoveryPhoneNumberContainer: React.FC<RecoveryPhoneNumberContainerProps> = ({
  phone,
}) => {
  const i18n = useContext(TranslationsContext);
  const [editing, setEditing] = useState(true);
  const [otpSent, setOtpSent] = useState(false);
  const successUrl = window.location.href.replace(window.location.hash, '');

  return (
    <div className="grid-x grid-margin-x grid-margin-y-small">
      <h2 className="cell">{i18n.gettext('Recovery phone number')}</h2>
      <div className="cell">
        <SendPhoneNumberForm
          defaultPhoneValue={phone}
          otpSent={otpSent}
          setOtpSent={setOtpSent}
          editing={editing}
          setEditing={setEditing}
        />
      </div>
      {otpSent && !editing && (
        <div className="cell">
          <VerifyOTPForm onSendSuccess={() => (window.location.href = successUrl)} />
        </div>
      )}
    </div>
  );
};

/**
 * Renders a React-based OTP input component to replace a standard HTML input field.
 *
 * @param {HTMLInputElement} otpInputField The original input field that will be
 * replaced with the OTP component
 * @returns {void}
 */
export const renderOtpInput = (otpInputField: HTMLInputElement) => {
  const { id, name, value, form } = otpInputField;
  const container = document.createElement('div');
  container.id = `${id}-container`;
  otpInputField.replaceWith(container);

  const root = createRoot(container);
  root.render(
    <Translations>
      <OtpInputField
        defaultValue={value}
        name={name}
        onPasteComplete={() => form?.submit()}
      />
    </Translations>
  );
};

/**
 * Initialize and render the RecoveryPhoneNumber component.
 */
const initRecoveryPhoneNumberSetup = (container: HTMLElement, phone: string) => {
  const root = createRoot(container);
  root.render(
    <Translations>
      <RecoveryPhoneNumberContainer phone={phone} />
    </Translations>
  );
};
export default initRecoveryPhoneNumberSetup;
