import PropTypes from 'prop-types';
import { useCallback, useMemo, useState } from 'react';
import useSWR, { useSWRConfig } from 'swr';
import InternalBrowser from './components/Browser';
import { STATUS_ERROR, STATUS_LOADING, STATUS_LOADED } from './constants';
import { BrowserContext } from './contexts';
import { IntegrationSettingsType } from './types';
import {
  getLocalStorageItem,
  setLocalStorageItem,
} from '../../mailmojo/assets/js/utils';

const storeCache = (data) => {
  const { username } = window.MailMojo.User;
  setLocalStorageItem(`${username}ContentBrowser`, JSON.stringify(data));
};

const getCache = () => {
  const { username } = window.MailMojo.User;
  const data = getLocalStorageItem(`${username}ContentBrowser`);
  // TODO: Temporary check against 'undefined' as it was stored in local storage by
  // mistake.
  return data && data !== 'undefined' ? JSON.parse(data) : {};
};

const useFilter = () => {
  const [filter, setFilter] = useState(getCache());
  const cacheSetter = useCallback(
    (newFilter) => {
      setFilter(newFilter);
      if (newFilter.integration) {
        storeCache(newFilter);
      }
    },
    [setFilter]
  );

  return [filter, cacheSetter];
};

const isValidFilter = (filter, settings) => {
  if (!settings || !filter.integration) {
    return false;
  }

  return settings.integrations.find(({ id }) => id === filter.integration) ?? false;
};

const Browser = ({ getContent, onSelect, onClose, settings, synchronizeContent }) => {
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [filter, setFilter] = useFilter();
  const {
    data: content,
    error: contentError,
    isLoading: isLoadingContent,
  } = useSWR(() => (isValidFilter(filter, settings) ? filter : false), getContent);
  const { mutate } = useSWRConfig();

  // Ensure a valid integration is set for the filter
  if (settings && !isValidFilter(filter, settings)) {
    setFilter({ integration: settings.integrations[0].id });
  }

  let status = isLoadingContent ? STATUS_LOADING : null;
  if (contentError) {
    status = STATUS_ERROR;
  } else if (content) {
    status = STATUS_LOADED;
  }

  const updateFilter = useCallback(
    (newFilter = {}) => {
      // Values that are undefined are cleared
      for (const [key, value] of Object.entries(newFilter)) {
        if (value === undefined) {
          delete newFilter[key];
          delete filter[key];
        }
      }

      // Make sure that we clear other filters when changing integration
      setFilter(
        newFilter.integration
          ? { integration: newFilter.integration }
          : { ...filter, ...newFilter }
      );
    },
    [filter, setFilter]
  );

  const triggerSync = useCallback(
    async (integration, source_id = '') => {
      setIsRefreshing(true);
      const { result } = await synchronizeContent(integration, source_id);

      if (result?.created > 0 || result?.updated > 0) {
        mutate(filter).then(() => setIsRefreshing(false));
      } else {
        setIsRefreshing(false);
      }
    },
    [filter, mutate, synchronizeContent]
  );

  const context = useMemo(
    () => ({ onSelect, onClose, triggerSync, updateFilter }),
    [onSelect, onClose, triggerSync, updateFilter]
  );

  if (!isValidFilter(filter, settings)) {
    return null;
  }

  return (
    <BrowserContext.Provider value={context}>
      <InternalBrowser
        filter={filter}
        isRefreshing={isRefreshing}
        items={content}
        settings={settings}
        status={status}
      />
    </BrowserContext.Provider>
  );
};

Browser.propTypes = {
  getContent: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  synchronizeContent: PropTypes.func.isRequired,
  settings: PropTypes.shape({
    integrations: PropTypes.arrayOf(IntegrationSettingsType).isRequired,
  }).isRequired,
};

export { Browser };
