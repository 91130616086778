import { useContext } from 'react';
import { createRoot } from 'react-dom/client';
import { Translations, TranslationsContext } from './translate';
import Status from './status';
import { formatNumber } from '../i18n';

interface ContactsStatusProps {
  numContacts: number;
  maxContacts: number;
  displayCapacity?: boolean;
}

/**
 * ContactsStatus React component.
 *
 * @param {ContactsStatusProps} props
 */
const ContactsStatus: React.FC<ContactsStatusProps> = ({
  numContacts,
  maxContacts,
  displayCapacity = false,
}) => {
  const i18n = useContext(TranslationsContext);

  return (
    <Status
      currentValue={numContacts}
      maxValue={maxContacts}
      displayCapacity={displayCapacity}
      toolTipText={i18n.sprintf(
        i18n.gettext(
          'You have %(num_contacts)s contacts of maximum %(max_contacts)s on your ' +
            'account.'
        ),
        {
          num_contacts: formatNumber(numContacts, 0),
          max_contacts: formatNumber(maxContacts, 0),
        }
      )}
      description={
        displayCapacity
          ? i18n.gettext('Capacity used').toLowerCase()
          : i18n.gettext('Contacts').toLowerCase()
      }
    />
  );
};

/**
 * Setup the Contact Status React component.
 *
 * @param {HTMLElement} container
 */
const createContactStatus = (container: HTMLElement) => {
  const render = (user: { numContacts: number; maxContacts: number }) => {
    const root = createRoot(container);
    root.render(
      <Translations>
        <ContactsStatus
          numContacts={user.numContacts}
          maxContacts={user.maxContacts}
          displayCapacity={container.dataset.displayCapacity === 'true'}
        />
      </Translations>
    );
  };

  if (window.MailMojo.User) {
    render(window.MailMojo.User);
  }
};

export default ContactsStatus;

export { createContactStatus };
