import React, { useContext } from 'react';

import ChecklistItem from '../../components/checklist-item';
import { Status } from '../../common/constants';
import { TranslationsContext } from '../../components/translate';

interface SMSSenderChecklistItemProps {
  sender: string;
  approved: boolean;
  checklistItemProps: object;
}

const SMSSenderChecklistItem: React.FC<SMSSenderChecklistItemProps> = ({
  sender,
  approved,
  ...checklistItemProps
}) => {
  const i18n = useContext(TranslationsContext);

  let descriptionText = sender
    ? i18n.sprintf(
        i18n.gettext(
          'This SMS will be sent with the sender name <strong>%(sender)s</strong>.'
        ),
        { sender }
      )
    : i18n.gettext('Sender name for SMS has not been set.');

  if (sender && !approved) {
    descriptionText +=
      ' ' + i18n.gettext('This sender name has not been approved yet.');
  }

  return (
    <ChecklistItem
      title={i18n.gettext('Sender')}
      status={approved ? Status.VALID : Status.INVALID}
      description={<p dangerouslySetInnerHTML={{ __html: descriptionText }} />}
      {...checklistItemProps}
    />
  );
};

export default SMSSenderChecklistItem;
