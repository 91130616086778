import { useContext, useState } from 'react';
import { TranslationsContext } from './translate';

interface CopyToClipboardProps {
  text: string;
}

const CopyToClipboard: React.FC<CopyToClipboardProps> = ({ text }) => {
  const i18n = useContext(TranslationsContext);
  const [hasCopied, setHasCopied] = useState(false);

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setHasCopied(true);
        setTimeout(() => setHasCopied(false), 3000);
      })
      .catch((error) => {
        console.error('Unable to copy text to clipboard', error);
      });
  };

  return (
    <div className="grid-x grid-padding-x grid-padding-y-small">
      <div className="cell" style={{ marginTop: '10px' }}>
        <div
          className="copyable-code flex-container flex-wrap callout warning
            align-middle"
        >
          <code className="code-inline flex-child-auto">{text}</code>
          <div>
            <button
              type="button"
              className="button secondary compact"
              onClick={copyToClipboard}
            >
              {hasCopied ? i18n.gettext('Copied!') : i18n.gettext('Copy')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CopyToClipboard;
