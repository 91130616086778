import PropTypes from 'prop-types';
import { Component, useContext } from 'react';

import ChecklistItem from '../../components/checklist-item';
import { StringField } from '../../components/fields';
import { TranslationsContext } from '../../components/translate';
import { get as getState } from '../../state';
import { patch } from '../../utils/api';

/**
 * <NameChecklistItem />
 *
 * A React component for specifying the domain where the popup has permissions to load.
 */
const NameChecklistItem = ({ value = '', error = null, ...checklistItemProps }) => {
  const i18n = useContext(TranslationsContext);
  return (
    <ChecklistItem
      title={i18n.gettext('Name')}
      description={value || <em>{i18n.gettext('Not set')}</em>}
      isValid={!error && value !== ''}
      {...checklistItemProps}
    >
      <StringField
        label={i18n.gettext('Give the popup a name.')}
        inputProps={{ name: 'name', defaultValue: value }}
        isRequired
        isErrorVisible={!!error}
        errorMessage={error}
      />
    </ChecklistItem>
  );
};

NameChecklistItem.propTypes = {
  value: PropTypes.string,
  error: PropTypes.string,
};

/**
 * <TagsChecklistItem />
 *
 * A React component for specifying tags the subscribers should get.
 */
const TagsChecklistItem = ({ value = [], error = null, ...checklistItemProps }) => {
  const i18n = useContext(TranslationsContext);
  return (
    <ChecklistItem
      title={i18n.gettext('Tags')}
      description={
        value && value.length > 0
          ? i18n.sprintf(
              i18n.gettext('Subscribers will be tagged with <em>%(tags)s</em>.'),
              {
                tags: value,
              }
            )
          : `<em>${i18n.gettext('Not set')}</em>`
      }
      isValid
      {...checklistItemProps}
    >
      <StringField
        label={i18n.gettext(
          'Specify any tags the subscribers should get.' +
            ' Separate multiple tags with a comma.'
        )}
        inputProps={{ name: 'tags', defaultValue: value.join(', ') }}
        isRequired={false}
        isErrorVisible={!!error}
        errorMessage={error}
      />
    </ChecklistItem>
  );
};

TagsChecklistItem.propTypes = {
  value: PropTypes.arrayOf(PropTypes.string),
  error: PropTypes.string,
};

/**
 * <RedirectUrlChecklistItem />
 *
 * A React component for specifying redirect URL after form submission.
 */
const RedirectUrlChecklistItem = ({
  value = '',
  error = null,
  ...checklistItemProps
}) => {
  const i18n = useContext(TranslationsContext);
  return (
    <ChecklistItem
      title={i18n.gettext('Confirmation page')}
      description={
        value
          ? i18n.sprintf(
              i18n.gettext('The subscriber will be redirected to <em>%(url)s</em>.'),
              {
                url: value,
              }
            )
          : `<em>${i18n.gettext('Not set')}</em>`
      }
      isValid={error ? false : value ? true : null}
      {...checklistItemProps}
    >
      <StringField
        label={i18n.gettext(
          'Enter a URL to redirect the subscriber to after subscribing. If left ' +
            "empty, the popup's confirmation will be shown."
        )}
        inputProps={{ type: 'url', name: 'redirect_url', defaultValue: value }}
        isRequired={false}
        isErrorVisible={!!error}
        errorMessage={error}
      />
    </ChecklistItem>
  );
};

RedirectUrlChecklistItem.propTypes = {
  value: PropTypes.string,
  error: PropTypes.string,
};

/**
 * <Checklist />
 *
 * Combines  name and tags checklist item components for form popups.
 */
class Checklist extends Component {
  static context = TranslationsContext;

  state = {
    name: '',
    tags: [],
    isSaving: false,
    current: null,
    error: null,
    previousError: null,
  };

  componentDidMount() {
    this.objToState(getState('popupform'));
  }

  handleOpen = (current) => {
    const { error } = this.state;
    this.setState({ current, previousError: error });
  };

  handleClose = (userCanceled = false) => {
    const { error, previousError } = this.state;

    this.setState({
      current: null,
      isSaving: false,
      error: userCanceled ? previousError : error,
      previousError: null,
    });
  };

  /**
   * Save form data through a patch request to the API.
   *
   * Depending on which checklist item that is open we build the data structure that is
   * sent to the API. If any error occurs we store it in the state so that it can be
   * displayed.
   *
   * TODO: Each sub component should define how its data is stored to the backend. So we
   * should probably pass in a submit handler to the individual item components, and not
   * rely on the default form submit event. The save button in each checklist item could
   * then have a click handler which extracts the current input value and wraps in the
   * proper data object as done here. The data object is then passed through to the
   * common save handler here.
   */
  handleSubmit = (event) => {
    const { id, current, error } = this.state;
    const { value } = current ? event.target[current] : { value: null };

    // Nothing to save or errors to present so we bail out for backend to handle further
    // validation and proceed.
    if (!current && !error) {
      return;
    }

    event.preventDefault();

    let data = null;

    if (current === 'name') {
      data = { name: value };
    } else if (current === 'tags') {
      data = {
        config: { subscribe_tags: value.split(/,\s*/).filter((t) => t) },
      };
    } else if (current === 'redirect_url') {
      data = {
        config: { redirect_url: value },
      };
    }

    if (data) {
      this.setState({ isSaving: true, error: null });

      patch(`/forms/${id}/`, data).then(
        (obj) => {
          this.objToState(obj);
          this.handleClose();
        },
        (result) => {
          let currentError = null;

          if (result?.errors) {
            currentError = result.errors.config
              ? result.errors.config[current]
              : result.errors[current];
          }

          this.setState({
            isSaving: false,
            error: { ...error, [current]: currentError[0] },
          });
        }
      );
    } else if (error) {
      // Might be more errors to present, but we only allow having one check list open
      // at the time.
      this.handleOpen(Object.keys(error)[0]);
    }
  };

  /**
   * Extracts data from a `form` object to state data.
   *
   * @param {Object} obj
   */
  objToState(obj) {
    const { current, error, name, tags, redirect_url } = this.state;
    const previousErrors = error || {};
    const newErrors = (obj.errors && obj.errors.config) || {};
    const mergedErrors = Object.entries({
      ...previousErrors,
      ...newErrors,
    }).reduce((filteredErrors, [key, value]) => {
      if (key !== current) {
        Object.assign(filteredErrors, { [key]: value });
      }

      return filteredErrors;
    }, {});

    this.setState({
      id: obj.id,
      name: obj.name || name,
      tags: obj.config.subscribe_tags || tags,
      redirect_url: obj.config.redirect_url ?? redirect_url,
      error: Object.keys(mergedErrors).length ? mergedErrors : null,
    });
  }

  render() {
    const { current, name, tags, redirect_url, error, isSaving } = this.state;
    const checklistItemProps = (key) => ({
      isOpen: current === key,
      isSaving,
      onOpen: () => this.handleOpen(key),
      onClose: () => this.handleClose(true),
    });

    return (
      <form onSubmit={this.handleSubmit} method="post" noValidate>
        <input type="hidden" name="next" value="" />
        <NameChecklistItem
          value={name}
          error={error && error.name}
          {...checklistItemProps('name')}
        />
        <TagsChecklistItem
          value={tags}
          error={error && error.tags}
          {...checklistItemProps('tags')}
        />
        <RedirectUrlChecklistItem
          value={redirect_url}
          error={error && error.redirect_url}
          {...checklistItemProps('redirect_url')}
        />
      </form>
    );
  }
}

export default Checklist;
